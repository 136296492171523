import React from 'react'
import { Modal, Form, Row, Col, Typography as TypographyAntd } from 'antd'
import { InputLink } from '../../ImportDataModal/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useState } from 'react'
import { onUpload } from './utils'
import { INTEGRATIONS } from 'modules/core/constants'
import { useHistory } from 'react-router-dom'
import TypeUploadOptions from '../../TypeUploadOptions/TypeUploadOptions'
import home from 'modules/home'
import login from 'modules/login'
import DownloadModal from '../DownloadModal/DownloadModal'

const { Text } = TypographyAntd

const SpreadsheetsModal = ({
  visible,
  onCancel,
  dataSpreadsheets,
  onRefetch,
  onChangeTypeOfLoad = () => {},
  typeOfLoad = null,
  enabledTypeOfLoad = false,
  periodId,
  conceptId,
  uploadSpreadsheets,
  downloadSpreadsheets,
  loggedUser: { folder_id },
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  let history = useHistory()

  const onClose = () => {
    form.resetFields()
    onCancel()
    setIsSaving(false)
  }

  const renderExtraBody = () => {
    if (enabledTypeOfLoad) {
      return (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Text>* {t('FIELD_LOAD_TYPE')}</Text>
            <TypeUploadOptions onChange={onChangeTypeOfLoad} value={typeOfLoad} />
          </Col>
        </Row>
      )
    }
  }

  return (
    <Modal
      title={t('ACTION_UPLOAD_DATA')}
      width={560}
      visible={visible}
      centered
      forceRender
      destroyOnClose
      onCancel={onClose}
      onOk={() =>
        onUpload({
          form,
          setIsLoading: setIsSaving,
          uploadSpreadsheets: (values) =>
            uploadSpreadsheets({ ...dataSpreadsheets, sheet_id: values.link }),
          onRefetch,
          onClose,
          onClick: () => history.push('/procesosdecarga'),
        })
      }
      okText={t('ACTION_UPLOAD')}
      cancelText={t('ACTION_CANCEL')}
      okButtonProps={{ loading: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
    >
      <InputLink form={form} extraBody={renderExtraBody()} />
      <Col span={24}>
        <div className="upload-moda-help">
          {t('INFO_UPLOAD_FILE_MODAL_DESCRIPTION')}{' '}
          <span onKeyUp={() => {}} role="button" onClick={() => setShowModal(true)}>
            {t('INFO_UPLOAD_FILE_DOWNLOAD_ACTION')}
          </span>
        </div>
      </Col>
      <DownloadModal
        title={INTEGRATIONS.KEYS.SPREADSHEETS_KEY}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        defaultValue={folder_id}
        dataSpreadsheets={{
          type_load: 'template',
          folder_id,
          params: { concept_id: conceptId, period_id: periodId },
        }}
      />
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  uploadSpreadsheets: home.actions.uploadSpreadsheets,
  downloadSpreadsheets: home.actions.downloadSpreadsheets,
}

export default connect(mapStateToProps, mapDispatchToProps)(SpreadsheetsModal)
