import _, { isEmpty } from 'lodash'
import React from 'react'
import { aggregators, PivotData, redColorScaleGenerator, spanSize } from '../../../Utilities'

function makeRenderer(opts = {}) {
  class TableRenderer extends React.PureComponent {
    getBasePivotSettings() {
      const props = this.props
      const colAttrs = props.cols
      const rowAttrs = props.rows

      const pivotData = new PivotData(this.props)
      const colKeys = pivotData.getColKeys()
      const rowKeys = pivotData.getRowKeys()

      const tableOptions = props.tableOptions
      const realMonths = !isEmpty(tableOptions?.realMonths) ? tableOptions.realMonths : []

      return Object.assign(
        {
          pivotData,
          colAttrs,
          rowAttrs,
          colKeys,
          rowKeys,
          realMonths,
        },
        TableRenderer.heatmapMappers(pivotData, props.tableColorScaleGenerator, colKeys, rowKeys),
      )
    }

    static heatmapMappers(pivotData, colorScaleGenerator, colKeys, rowKeys) {
      let valueCellColors = () => {}
      let rowTotalColors = () => {}
      let colTotalColors = () => {}
      if (opts.heatmapMode) {
        const rowTotalValues = colKeys.map((x) => pivotData.getAggregator([], x).value())
        rowTotalColors = colorScaleGenerator(rowTotalValues)
        const colTotalValues = rowKeys.map((x) => pivotData.getAggregator(x, []).value())
        colTotalColors = colorScaleGenerator(colTotalValues)

        if (opts.heatmapMode === 'full') {
          const allValues = []
          rowKeys.map((r) =>
            colKeys.map((c) => allValues.push(pivotData.getAggregator(r, c).value())),
          )
          const colorScale = colorScaleGenerator(allValues)
          valueCellColors = (r, c, v) => colorScale(v)
        } else if (opts.heatmapMode === 'row') {
          const rowColorScales = {}
          rowKeys.map((r) => {
            const rowValues = colKeys.map((x) => pivotData.getAggregator(r, x).value())
            rowColorScales[r] = colorScaleGenerator(rowValues)
          })
          valueCellColors = (r, c, v) => rowColorScales[r](v)
        } else if (opts.heatmapMode === 'col') {
          const colColorScales = {}
          colKeys.map((c) => {
            const colValues = rowKeys.map((x) => pivotData.getAggregator(x, c).value())
            colColorScales[c] = colorScaleGenerator(colValues)
          })
          valueCellColors = (r, c, v) => colColorScales[c](v)
        }
      }

      return { valueCellColors, rowTotalColors, colTotalColors }
    }

    clickHandler(pivotData, rowValues, colValues) {
      const colAttrs = this.props.cols
      const rowAttrs = this.props.rows
      const value = pivotData.getAggregator(rowValues, colValues).value()
      const filters = {}
      const colLimit = Math.min(colAttrs.length, colValues.length)
      for (let i = 0; i < colLimit; i++) {
        const attr = colAttrs[i]
        if (colValues[i] !== null) {
          filters[attr] = colValues[i]
        }
      }
      const rowLimit = Math.min(rowAttrs.length, rowValues.length)
      for (let i = 0; i < rowLimit; i++) {
        const attr = rowAttrs[i]
        if (rowValues[i] !== null) {
          filters[attr] = rowValues[i]
        }
      }
      return (e) =>
        this.props.tableOptions.clickCallback(e, value, filters, pivotData, pivotData.props.vals[0])
    }

    checkDateInReal(colKey, realMonths) {
      const pattern = /^\d{4}-\d{2}$/
      const baseSet = new Set(realMonths)
      const foundItem = _.find(colKey, (it) => pattern.test(it) && baseSet.has(it))
      return !!foundItem
    }

    renderColHeaderRow(attrName, attrIdx, pivotSettings) {
      const { colAttrs, rowAttrs, colKeys } = pivotSettings

      const spanCell =
        attrIdx === 0 && rowAttrs.length !== 0 ? (
          <th className={`header_0`} colSpan={rowAttrs.length} rowSpan={colAttrs.length} />
        ) : null

      const attrNameCell = (
        <th
          key="label"
          className={`pvtAxisLabel header_${rowAttrs.length} header_tot`}
          style={{ textAlign: 'right' }}
        >
          {attrName}
        </th>
      )

      const attrValueCells = colKeys.map((colKey, i) => {
        const x = spanSize(colKeys, i, attrIdx)
        if (x === -1) {
          return null
        }
        return (
          <th
            className="pvtColLabel"
            key={`colKey-${i}`}
            colSpan={x}
            rowSpan={attrIdx === colAttrs.length - 1 && rowAttrs.length !== 0 ? 2 : 1}
          >
            {colKey[attrIdx]}
          </th>
        )
      })

      const totalCell =
        attrIdx === 0 ? (
          <th
            key="total"
            className="pvtTotalLabel"
            rowSpan={colAttrs.length + (rowAttrs.length === 0 ? 0 : 1)}
          >
            TOTAL
          </th>
        ) : null

      const cells = [spanCell, attrNameCell, ...attrValueCells, totalCell]
      return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
    }

    renderRowHeaderRow(pivotSettings) {
      const { rowAttrs, colAttrs } = pivotSettings
      return rowAttrs.length !== 0 ? (
        <tr key="rowHdr">
          {rowAttrs.map(function (r, i) {
            return (
              <th className={`pvtAxisLabel header_${i}`} key={`rowAttr${i}`}>
                {r}
              </th>
            )
          })}
          <th className={`pvtTotalLabel header_${rowAttrs.length} header_tot`}>
            {colAttrs.length === 0 ? 'Totals' : null}
          </th>
        </tr>
      ) : null
    }

    renderTableRow(rowKey, rowIdx, pivotSettings) {
      const {
        rowKeys,
        colKeys,
        pivotData,
        rowAttrs,
        colAttrs,
        rowTotalColors,
        valueCellColors,
        realMonths,
      } = pivotSettings

      const attrValueCells = rowKey.map((txt, j) => {
        const x = spanSize(rowKeys, rowIdx, j)
        if (x === -1) {
          return null
        }

        return (
          <th
            key={`rowKeyLabel-${rowIdx}-${j}`}
            className={`pvtRowLabel header_${j}`}
            rowSpan={x}
            colSpan={j === rowAttrs.length - 1 && colAttrs.length !== 0 ? 2 : 1}
          >
            {txt}
          </th>
        )
      })

      const valueCells = colKeys.map((colKey, j) => {
        const agg = pivotData.getAggregator(rowKey, colKey)
        const aggValue = agg.value()
        const style = valueCellColors(rowKey, colKey, aggValue)

        let hasRealMonth = false
        let handleClick = this.clickHandler(pivotData, rowKey, colKey)
        if (realMonths.length > 0) {
          hasRealMonth = this.checkDateInReal(colKey, realMonths)
          if (hasRealMonth) {
            handleClick = () => {}
          }
        }

        return (
          <td
            className="pvtVal"
            key={`pvtVal-${rowIdx}-${j}`}
            onClick={handleClick}
            style={{ ...(style ? style : hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
          >
            {agg.format(aggValue)}
          </td>
        )
      })

      const agg = pivotData.getAggregator(rowKey, [])
      const aggValue = agg.value()
      const style = rowTotalColors(aggValue)

      const totalCell = (
        <td key="total" className="pvtTotal" style={style}>
          {agg.format(aggValue)}
        </td>
      )

      const rowCells = [...attrValueCells, ...valueCells, totalCell]
      return <tr key={`keyRow-${rowIdx}`}>{rowCells}</tr>
    }

    renderTotalsRow(pivotSettings) {
      // Render the final totals rows that has the totals for all the columns.

      const { pivotData, colKeys, colAttrs, rowAttrs, realMonths, rowTotalColors } = pivotSettings

      const totalLabelCell = (
        <th className="pvtTotalLabel" colSpan={rowAttrs.length + (colAttrs.length === 0 ? 0 : 1)}>
          TOTAL
        </th>
      )

      const totalValueCells = colKeys.map((colKey, i) => {
        const agg = pivotData.getAggregator([], colKey)
        const aggValue = agg.value()
        const style = rowTotalColors([], colKey, aggValue)

        let hasRealMonth = false
        if (realMonths.length > 0) {
          hasRealMonth = this.checkDateInReal(colKey, realMonths)
        }

        return (
          <td
            className="pvtTotal"
            key={`total-${i}`}
            style={{ ...(style ? style : hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
          >
            {agg.format(aggValue)}
          </td>
        )
      })

      const agg = pivotData.getAggregator([], [])
      const aggValue = agg.value()
      const grandTotalCell = (
        <td key="total" className="pvtGrandTotal">
          {agg.format(aggValue)}
        </td>
      )

      const totalCells = [totalLabelCell, ...totalValueCells, grandTotalCell]

      return <tr key="total">{totalCells}</tr>
    }

    render() {
      if (this.cachedProps !== this.props) {
        this.cachedProps = this.props
        this.cachedBasePivotSettings = this.getBasePivotSettings()
      }
      const { colAttrs, rowAttrs, rowKeys } = this.cachedBasePivotSettings
      const pivotSettings = this.cachedBasePivotSettings

      return (
        <table className="pvtTable">
          <thead>
            {colAttrs.map((it, index) => this.renderColHeaderRow(it, index, pivotSettings))}
            {rowAttrs.length !== 0 && this.renderRowHeaderRow(pivotSettings)}
          </thead>
          <tbody>
            {rowKeys.map((r, i) => this.renderTableRow(r, i, pivotSettings))}
            {this.renderTotalsRow(pivotSettings)}
          </tbody>
        </table>
      )
    }
  }

  TableRenderer.defaultProps = PivotData.defaultProps
  TableRenderer.propTypes = PivotData.propTypes
  TableRenderer.defaultProps.aggregators = aggregators
  TableRenderer.defaultProps.tableColorScaleGenerator = redColorScaleGenerator
  TableRenderer.defaultProps.tableOptions = {}
  return TableRenderer
}

export default makeRenderer
