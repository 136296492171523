import { DownOutlined } from '@ant-design/icons'
import { message, notification } from 'antd'
import i18n from 'i18next'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, DropdownMenu } from '../index'
import { CustomBottomModal } from './components'
import { generateMessageError } from 'modules/core/utils'
import societies from '../../../societies'
import SOCIETIES from '../../constants/societies'

const CustomBottomAction = ({
  extraButtons,
  fetchFunctionsButtonsTenant,
  functionsButtonsTenant,
  FunctionsButtonsExecute,
}) => {
  const { t } = useTranslation()

  const [loadingAction, setLoadingAction] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentAction, setCurrentAction] = useState()

  useEffect(() => {
    setLoadingAction(true)
    Promise.all([fetchFunctionsButtonsTenant(extraButtons)])
      .then(() => {
        setLoadingAction(false)
      })
      .catch((e) => {
        setLoadingAction(false)
      })
    // eslint-disable-next-line
  }, [fetchFunctionsButtonsTenant])

  const actions = functionsButtonsTenant.values

  const onClick = (url) => {
    window.open(url, '_blank')
  }

  const onTitle = (method) => {
    if (method === 'spreadsheets') {
      return i18n.t('FEEDBACK_SPREADSHEET_TEXT')
    }
    return i18n.t('FEEDBACK_DEFAULT_TEXT')
  }

  const isValidURL = (value) => {
    if (!_.isString(value)) {
      return false
    }

    try {
      new URL(value)
      return true
    } catch (err) {
      return false
    }
  }

  const handleClick = (customButton) => {
    const typeAction = customButton?.action || null
    if (customButton.is_upload_file) {
      setCurrentAction(customButton)
      setShowModal(true)
    } else {
      const key = `open${Date.now()}`
      message.loading({
        key,
        content: i18n.t('FEEDBACK_LOAD_TEXT'),
        duration: 0,
      })
      const responseType = typeAction === SOCIETIES.SOCIETIES_KEYS.ACTION_DOWNLOAD ? 'blob' : null
      FunctionsButtonsExecute(
        customButton?.function_call.slice(6),
        [],
        { function_id: customButton?.id, ...extraButtons },
        {},
        responseType,
      )
        .then((res) => {
          const data = res.payload.data
          if (data?.url && isValidURL(data?.url)) {
            message.destroy(key)
            notification.info({
              message: i18n.t('FEEDBACK_FUNCTION_ACTION_SUCCESS'),
              description: (
                <>
                  <span>{onTitle(data?.method)}</span>{' '}
                  <span
                    onClick={() => onClick(data.url)}
                    style={{ color: '#0047ba', cursor: 'pointer' }}
                  >
                    {i18n.t('DASHBOARD_WHATSAPP_CONTACT_LINK')}
                  </span>
                </>
              ),
              duration: 0,
            })
          } else {
            message.destroy(key)
            message.success(i18n.t('FEEDBACK_FUNCTION_ACTION_SUCCESS'), 8)
          }
        })
        .catch((error) => {
          message.destroy(key)
          notification.error({
            message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error, 'FEEDBACK_LOAD_DATA_ERROR'),
            duration: 0,
          })
        })
    }
  }

  return (
    <>
      {functionsButtonsTenant.values.length > 0 && (
        <DropdownMenu
          title={t('ACTION_MORE')}
          placement="bottomRight"
          menu={actions.map((it) => {
            return {
              title: it.name_button,
              key: it.id,
              onClick: () => handleClick(it),
            }
          })}
        >
          <Button.DefaultWhitRightIcon
            icon={<DownOutlined />}
            title="ACTION_MORE"
            loading={loadingAction}
          />
        </DropdownMenu>
      )}
      <CustomBottomModal
        showModal={showModal}
        setShowModal={setShowModal}
        currentAction={currentAction}
        setCurrentAction={setCurrentAction}
        handleExecute={FunctionsButtonsExecute}
        extraButtons={extraButtons}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  functionsButtonsTenant: societies.selectors.getFunctionsButtonsTenant(state),
})

const mapDispatchToProps = {
  fetchFunctionsButtonsTenant: societies.actions.fetchFunctionsButtonsTenant,
  FunctionsButtonsExecute: societies.actions.FunctionsButtonsExecute,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomBottomAction)
