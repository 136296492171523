export const NAME = 'home'

export const UPLOAD_INITIAL_FILE = `${NAME}/UPLOAD_INITIAL_FILE`
export const UPLOAD_INITIAL_FILE_SUCCESS = `${NAME}/UPLOAD_INITIAL_FILE_SUCCESS`

export const SAVE_CONFIG = `${NAME}/SAVE_CONFIG`

export const SET_STEP = `${NAME}/SET_STEP`

export const SET_DATA_CONNECTORS = `${NAME}/SET_DATA_CONNECTORS`

export const DELETE_FILE = `${NAME}/DELETE_FILE`

export const SET_FILE_DATA = `${NAME}/SET_FILE_DATA`

export const SET_CLEAN_DATA = `${NAME}/SET_CLEAN_DATA`

export const SET_DATA_SELECT = `${NAME}/SET_DATA_SELECT`

export const SET_SELECTION = `${NAME}/SET_SELECTION`

export const DELETE_SELECTION = `${NAME}/DELETE_SELECTION`

export const ADD_NEW_SELECTION = `${NAME}/ADD_NEW_SELECTION`

export const CONNECT_ERP = `${NAME}/CONNECT_ERP`
export const CONNECT_ERP_SUCCESS = `${NAME}/CONNECT_ERP_SUCCESS`
export const CONNECT_ERP_FAIL = `${NAME}/CONNECT_ERP_FAIL`

export const FETCH_STATUS_CONNECTON = `${NAME}/FETCH_STATUS_CONNECTON`
export const FETCH_STATUS_CONNECTON_SUCCESS = `${NAME}/FETCH_STATUS_CONNECTON_SUCCESS`
export const FETCH_STATUS_CONNECTON_FAIL = `${NAME}/FETCH_STATUS_CONNECTON_FILE`

export const DOWNLOAD_SPREADSHEETS = `${NAME}/DOWNLOAD_SPREADSHEETS`
export const UPLOAD_SPREADSHEETS = `${NAME}/UPLOAD_SPREADSHEETS`
export const UPDATE_SPREADSHEETS = `${NAME}/UPDATE_SPREADSHEETS`

export const UPLOAD_FILE_TEST = `${NAME}/UPLOAD_FILE_TEST`
export const UPLOAD_FILE_TEST_SUCCESS = `${NAME}/UPLOAD_FILE_TEST_SUCCESS`
export const UPLOAD_FILE_TEST_FAIL = `${NAME}/UPLOAD_FILE_TEST_FAIL`

//dashboard

export const FETCH_BUDGET_TYPE_CARDS = `${NAME}/FETCH_BUDGET_TYPE_CARDS`
export const FETCH_BUDGET_TYPE_CARDS_SUCCESS = `${NAME}/FETCH_BUDGET_TYPE_CARDS_SUCCESS`
export const FETCH_BUDGET_TYPE_CARDS_FAIL = `${NAME}/FETCH_BUDGET_TYPE_CARDS_FAIL`

export const FETCH_REPORTS_CARDS = `${NAME}/FETCH_REPORTS_CARDS`
export const FETCH_REPORTS_CARDS_SUCCESS = `${NAME}/FETCH_REPORTS_CARDS_SUCCESS`
export const FETCH_REPORTS_CARDS_FAIL = `${NAME}/FETCH_REPORTS_CARDS_FAIL`

export const FETCH_DATA_PROJECTION_CHART = `${NAME}/FETCH_DATA_PROJECTION_CHART`
export const FETCH_DATA_PROJECTION_CHART_SUCCESS = `${NAME}/FETCH_DATA_PROJECTION_CHART_SUCCESS`
export const FETCH_DATA_PROJECTION_CHART_FAIL = `${NAME}/FETCH_DATA_PROJECTION_CHART_FAIL`

export const FETCH_DATA_BUDGET_TYPE_CHART = `${NAME}/FETCH_DATA_BUDGET_TYPE_CHART`
export const FETCH_DATA_BUDGET_TYPE_CHART_SUCCESS = `${NAME}/FETCH_DATA_BUDGET_TYPE_CHART_SUCCESS`
export const FETCH_DATA_BUDGET_TYPE_CHART_FAIL = `${NAME}/FETCH_DATA_BUDGET_TYPE_CHART_FAIL`

export const FETCH_DATA_DIMENSION_CHART = `${NAME}/FETCH_DATA_DIMENSION_CHART`
export const FETCH_DATA_DIMENSION_CHART_SUCCESS = `${NAME}/FETCH_DATA_DIMENSION_CHART_SUCCESS`
export const FETCH_DATA_DIMENSION_CHART_FAIL = `${NAME}/FETCH_DATA_DIMENSION_CHART_FAIL`
