import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'modules/core/customHooks'
import { Spin, Tabs, Space } from 'antd'
import { PeriodNavigator } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { PROJECTION, REPORT } from 'modules/core/constants'
import { client } from 'httpclient'
import { connect } from 'react-redux'
import ActivityTrackingDetail from './ActivityTrackingDetail'
import processes from 'modules/processes'

import './ProjectionTrackingScreen.scss'

const { TabPane } = Tabs

const ACTIVITY = 'activity'

const ProjectionTrackingScreen = ({ fetchAuditList, auditList }) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [projectionDetail, setProjectionDetail] = useState({})

  const { t } = useTranslation()

  let { periodId, projectionId, conceptName, forecastId, step } = useParams()
  let query = useQuery()

  const urlParams = new URLSearchParams(useLocation().search)
  const type = urlParams.get('type')

  const FETCH_URL_DETAIL = useMemo(() => {
    return {
      planning: {
        url: `/planning/get/${projectionId}`,
        key: `${projectionId}-${step && step.toLowerCase()}`,
      },
      [PROJECTION.FORECAST]: {
        url: `/planning/forecast/get/${projectionId}`,
        key: `${projectionId}-${step && step.toLowerCase()}`,
      },
      [PROJECTION.REAL]: {
        url: '',
        key: `${periodId}-${projectionId}`,
      },
      [REPORT.REPORTS_KEYS.REPORTE_KEY]: {
        url: '',
        key: '',
      },
    }
  }, [projectionId, periodId, step])

  useEffect(() => {
    if (FETCH_URL_DETAIL[type]) {
      setIsScreenLoading(true)
      client
        .get(`${FETCH_URL_DETAIL[type].url}`)
        .then((response) => {
          setProjectionDetail(response.data)
          setIsScreenLoading(false)
        })
        .catch(() => setIsScreenLoading(false))
    } else {
      setIsScreenLoading(false)
    }
  }, [type, projectionId, FETCH_URL_DETAIL])

  useEffect(() => {
    setIsScreenLoading(true)
    fetchAuditList(type, FETCH_URL_DETAIL[type].key).then(() => setIsScreenLoading(false))
  }, [fetchAuditList, type, FETCH_URL_DETAIL])

  const renderHeader = () => {
    const customItem = () => {
      switch (type) {
        case 'planning':
          return [
            { name: t('LABEL_PLANNING'), url: `/planificacion/${periodId}` },
            {
              name: projectionDetail.name,
              url: `/planificacion/${periodId}/${conceptName}/${projectionId}`,
            },
          ]
        case PROJECTION.REAL:
          return [
            {
              name: t('LABEL_CONTROL'),
              url: `/seguimiento/${periodId}`,
            },
            {
              name: conceptName,
              url: `/seguimiento/${periodId}/${conceptName}/${projectionId}`,
            },
          ]
        case PROJECTION.FORECAST:
          return [
            { name: t('LABEL_FORECAST'), url: `/forecast/${periodId}` },
            {
              name: `${t('LABEL_DETAIL')}: ${t('LABEL_FORECAST')}`,
              url: `/forecast/${periodId}/${forecastId}`,
            },
            {
              name: projectionDetail.name,
              url: `/forecast/${periodId}/${forecastId}/${conceptName}/${projectionId}/`,
            },
          ]
        default:
          return []
      }
    }

    return <PeriodNavigator items={[...customItem(), ...[{ name: t('LABEL_ACTIVITIES') }]]} />
  }

  const renderTrackingTabs = () => (
    <Tabs defaultActiveKey={query.get('tab') !== null ? query.get('tab') : ACTIVITY}>
      <TabPane tab={t('LABEL_ACTIVITY')} key={ACTIVITY}>
        <Space className="projection-tracking-screen-space" direction="vertical" size="large">
          <ActivityTrackingDetail dataSource={auditList} loading={isScreenLoading} />
        </Space>
      </TabPane>
    </Tabs>
  )

  return (
    <SiderLayout className="projection-tracking-screen">
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && (
        <>
          {renderHeader()}
          {renderTrackingTabs()}
        </>
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  auditList: processes.selectors.getAuditList(state),
})

const mapDispatchToProps = {
  fetchAuditList: processes.actions.fetchAuditList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectionTrackingScreen)
