import React from 'react'
import { Popconfirm } from 'antd'
import { Button, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'

const DeleteSelectionButton = ({ onConfirm, disabled, isDeletingAll }) => {
  const { t } = useTranslation()
  return (
    <Popconfirm
      placement="bottomRight"
      title={t('POPCONFIRM_DELETE_SELECTION_TITLE')}
      okText={t('ACTION_DELETE')}
      onConfirm={onConfirm}
      cancelText={t('ACTION_CANCEL')}
    >
      <Button.Icon
        title={isDeletingAll ? t('ACTION_DELETE_PAGE') : t('ACTION_DELETE_SELECTION')}
        icon={<Typography.Icon icon={DeleteOutlined} level={1} />}
        disabled={disabled}
      />
    </Popconfirm>
  )
}

export default DeleteSelectionButton
