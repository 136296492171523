import { notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onUpload = ({
  form,
  setIsLoading,
  uploadSpreadsheets,
  onRefetch,
  onClose,
  onClick,
}) => {
  form.validateFields().then((values) => {
    setIsLoading(true)
    uploadSpreadsheets(values)
      .then(() => {
        onRefetch()
        notification.success({
          message: i18n.t('FEEDBACK_EVERYTHING_READY'),
          description: (
            <>
              <span>{i18n.t('FEEDBACK_UPLOAD_DATA_FILE_SUCCESS')}</span>
              <span onClick={onClick} style={{ color: '#0047ba', cursor: 'pointer' }}>
                {i18n.t('LABEL_PROCESS_UPLOAD')}
              </span>
            </>
          ),
          duration: 0,
        })
        onClose()
      })
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_LOAD_DATA_ERROR'),
          duration: 0,
        })
        onClose()
      })
  })
}
