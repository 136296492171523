import { Steps, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

const { Step } = Steps

const ProjectionSteps = ({ current, setCurrent, dataProjectionPhase, disablePhases }) => {
  const { t } = useTranslation()
  return (
    <Steps
      type="navigation"
      size="small"
      current={current}
      progressDot={() => null}
      onChange={(val) => setCurrent(val)}
      className="steps"
    >
      {dataProjectionPhase.map((phase) => (
        <Step
          key={phase.key}
          title={
            <Tooltip
              title={
                disablePhases.map((p) => p.key).includes(phase.key)
                  ? t('PLANNING_PHASES_INFORMATIVE_DISABLED_TOOLTIP_TITLE')
                  : ''
              }
            >
              {t(phase.title)}
            </Tooltip>
          }
          disabled={disablePhases.map((p) => p.key).includes(phase.key)}
        />
      ))}
    </Steps>
  )
}

export default ProjectionSteps
