import React, { useCallback, useRef, useState } from 'react'
import { ExportOutlined } from '@ant-design/icons'
import { Card, Dropdown, Menu, message } from 'antd'
import {
  ButtonActionBar,
  Comments,
  DropdownMenu,
  LineChart,
  Typography,
} from 'modules/core/components'
import { CHARTS, GENERAL } from 'modules/core/constants'
import { executeExportAsImage, generateMessageError } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { CommentOutlined } from '@ant-design/icons'
import login from 'modules/login'
import configuration from 'modules/configuration'
import numeral from 'numeral'

import './ReportLineChart.scss'
import _ from 'lodash'

const ReportLineChart = ({
  title,
  chartTitle = '',
  data,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  hasComment,
  setHasComment,
  loggedUser,
  createComment,
  removeNewComment,
}) => {
  const [viewComment, setViewComment] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const chartRef = useRef(null)
  const { t } = useTranslation()

  const onExportChart = useCallback((props) => {
    executeExportAsImage({ ...props })
  }, [])

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${commentKey}-chart-0`,
      module: commentKey,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  const renderExtraActions = () => {
    return (
      <ButtonActionBar>
        {(viewComment ||
          dataComments.filter((el) => el.key === `${commentKey}-chart-0` && !el.resolved).length >
            0) && (
          <Comments
            hasComment={
              !_.isEmpty(hasComment?.chart)
                ? !_.isEmpty(hasComment?.chart[commentKey]) && hasComment?.chart[commentKey]['0']
                : false
            }
            onClick={(visible) => {
              setHasComment({ type: 'chart', data: visible, key: commentKey, id: 0 })
              !visible && removeNewComment()
              !visible && setViewComment(visible)
            }}
            comments={dataComments.filter(
              (el) => (el.key === `${commentKey}-chart-0` && !el.resolved) || !el.text,
            )}
            setData={setDataComments}
            loading={isSaving || loadingComments}
            onRefetchComments={onRefetchComments}
            onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
              handleCreate({ text, usersIds, idComment, setIsNewThread })
            }
            onCleanHasComment={() => {
              setHasComment({ type: 'chart', data: false, key: commentKey, id: 0 })
              setViewComment(false)
            }}
          >
            <Typography.Icon
              style={{ cursor: 'pointer' }}
              title={t('LABEL_COMMENTS')}
              icon={CommentOutlined}
            />
          </Comments>
        )}
        <DropdownMenu
          title={t('ACTION_MORE')}
          placement="bottomRight"
          menu={[
            {
              title: t('ACTION_EXPORT'),
              icon: <ExportOutlined />,
              submenu: CHARTS.EXPORT_FORMAT_TYPE_OPTIONS.map((formatType) => {
                return {
                  title: formatType.name,
                  key: formatType.id,
                  onClick: () =>
                    onExportChart({
                      chartRef: chartRef.current,
                      format: formatType.id,
                      onExport: formatType.onAction,
                      props: formatType.props,
                      title: chartTitle,
                    }),
                }
              }),
            },
          ]}
        />
      </ButtonActionBar>
    )
  }

  return (
    <Dropdown
      trigger={['contextMenu']}
      overlay={
        <Menu>
          {GENERAL.OPTION_COMMENT.map((el) => (
            <Menu.Item
              key={el.key}
              icon={<CommentOutlined />}
              onClick={() => {
                setViewComment(true)
                setHasComment({
                  type: 'chart',
                  data: !_.isEmpty(hasComment?.chart)
                    ? !_.isEmpty(hasComment?.chart[commentKey])
                      ? !hasComment?.chart[commentKey]['0']
                      : true
                    : true,
                  key: commentKey,
                  id: 0,
                })
              }}
            >
              {t(el.label)}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <div ref={chartRef}>
        <Card
          className="report-line-chart-card"
          title={title}
          headStyle={{ height: 56 }}
          extra={renderExtraActions()}
        >
          <LineChart
            containerHeight={300}
            data={data}
            colors={CHARTS.COLORS}
            axisLeft={{
              format: (value) => `$ ${numeral(value).format('0,0[.]0 a')}`,
            }}
            value={data.map((val) => val)}
          />
        </Card>
      </div>
    </Dropdown>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportLineChart)
