import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Spin, Row, Col, Space, Alert, Popover } from 'antd'
import { Button, ButtonActionBar, EmptyScreen, Typography } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { PeriodModal, PeriodsList } from './components'
import { isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import planning from 'modules/planning'
import configuration from 'modules/configuration'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'

import './Periods.scss'

const { PLANNING__GLOBAL_BUDGET__ABM } = ROLES
const CONFIG_MODULE_URL = '/configuracion/'

const Periods = ({
  periodList,
  fetchPeriodList,
  fetchDimensionsList,
  fetchDimensionValues,
  dimensionsList,
}) => {
  const [showCreationModal, setShowCreationModal] = useState(false)
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [isConfigLoading, setIsConfigLoading] = useState(false)
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(() => {
    setIsScreenLoading(true)
    fetchPeriodList().then(() => setIsScreenLoading(false))
  }, [fetchPeriodList])

  useEffect(() => {
    setIsConfigLoading(true)
    fetchDimensionsList()
      .then(() => setIsConfigLoading(false))
      .catch(() => setIsConfigLoading(false))
  }, [fetchDimensionsList, fetchDimensionValues])

  const canUserCreate = isUserAllowed([PLANNING__GLOBAL_BUDGET__ABM])

  const renderHeader = () => (
    <Typography.Headline level={5}>{t('PERIOD_TITLE')}</Typography.Headline>
  )

  const renderUserActions = () =>
    canUserCreate && (
      <ButtonActionBar>
        <Button.Primary onClick={() => setShowCreationModal(true)} title="PERIOD_CREATE_ACTION" />
      </ButtonActionBar>
    )

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PERIOD_TITLE')}
      description={t('PERIOD_EMPTY_STATE_TEXT')}
      footer={
        canUserCreate && (
          <Popover content={renderGoToConfigButton({ url: CONFIG_MODULE_URL })}>
            <Button.Primary onClick={() => setShowCreationModal(true)} title="ACTION_START" />
          </Popover>
        )
      }
    />
  )

  const renderGoToConfigButton = ({ url }) => {
    return (
      <Space direction="vertical" size="small" align="center">
        {t('PERIOD_ONBOARDING_INFORMATIVE_TOOLTIP_DESCRIPTION')}
        <Button.Link onClick={() => history.push(url)} title="ACTION_GO_TO_CONFIGURE" />
      </Space>
    )
  }

  const renderAlertInfo = () => (
    <Alert
      message={t('PERIOD_ALERT_TITLE')}
      description={t('PERIOD_ALERT_DESCRIPTION')}
      type="info"
      showIcon
      closable
    />
  )

  const refresData = () => {
    setIsScreenLoading(true)
    fetchPeriodList().then(() => setIsScreenLoading(false))
  }

  const renderList = () => (
    <PeriodsList
      dataSource={periodList}
      onFetchPeriods={refresData}
      disabled={!isConfigLoading && dimensionsList.count === 0}
      onRefetch={() => fetchPeriodList()}
    />
  )

  return (
    <SiderLayout>
      <div className="periods-container">
        <Spin spinning={isScreenLoading || isConfigLoading} size="large" />
        {!isScreenLoading && periodList.length <= 0 && renderNoDataScreen()}
        {!isScreenLoading && !isConfigLoading && periodList.length > 0 && (
          <Row gutter={[24, 24]}>
            {!isConfigLoading && dimensionsList.count === 0 && (
              <Col span={24}>{renderAlertInfo()}</Col>
            )}
            <Col span={24}>{renderHeader()}</Col>
            <Col span={24}>{renderUserActions()}</Col>
            <Col span={24}>{renderList()}</Col>
          </Row>
        )}
        <PeriodModal
          visible={showCreationModal}
          onClose={() => setShowCreationModal(false)}
          onFetchPeriods={refresData}
        />
      </div>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
  dimensionsList: configuration.selectors.getDimensionsList(state),
})

const mapDispatchToProps = {
  fetchPeriodList: planning.actions.fetchPeriodList,
  fetchDimensionValues: configuration.actions.fetchDimensionValues,
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Periods)
