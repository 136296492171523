import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { Col, Row, Spin, Tabs } from 'antd'
import { connect } from 'react-redux'
import { INTEGRATIONS, TABLES, TAB_KEYS } from 'modules/core/constants'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  ComparisonTable,
  EvolutionTable,
  TableTypeSelect,
  GlobalProjectionLineChart,
  DownloadModal,
} from 'modules/core/components'
import { setPhaseAvailable } from 'modules/core/utils'
import reports from 'modules/reports'
import planning from 'modules/planning'
import login from 'modules/login'
import moment from 'moment'
import ComparisonPivotTable from './components/ComparisonPivotTable'
import EvolutionPivotTable from './components/EvolutionPivotTable'

const TabsReports = ({
  tableId,
  setTableId,
  analysisList,
  loading,
  dataPhaseBudget,
  stepsBudgetInstance,
  dataPeriod,
  globalBudgetName,
  selectedDates,
  filters,
  tableLoading,
  allDimensionsList,
  defaultPhase,
  setDefaultPhase,
  auxCurrencyId,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  fetchGlobalBudgetComparisonTable,
  globalBudgetComparisonTable,
  fetchGlobalBudgetEvolutionTable,
  globalBudgetEvolutionTable,
  fetchGlobalBudgetChart,
  globalBudgetChart,
  fetchEvolutionTableOpenRow,
  fetchComparisonTableOpenRow,
  downloadGlobalBudgeEvolutiontXLS,
  downloadGlobalBudgeComparisontXLS,
  loggedUser: { folder_id },
  fetchDataEvolutionByOverallBudget,
  fetchDataComparativeByOverallBudget,
}) => {
  // Feature flag
  const enabledEvolution = useFeatureIsOn('feature-react-pivot-evolution')
  const enabledComparative = useFeatureIsOn('feature-react-pivot-comparative')

  const [periodTableSettings, setPeriodTableSettings] = useState(TABLES.DEFAULT_SETTINGS)
  const [isEvolutionLoading, setIsEvolutionLoading] = useState(false)
  const [isComparisonLoading, setIsComparisonLoading] = useState(false)
  const [tableKey, setTableKey] = useState(TAB_KEYS.TAB_KEY_EVOLUTION)
  const [periodTableColumns, setPeriodTableColumns] = useState([])
  const [showModal, setShowModal] = useState(false)
  // const [reportLineSelected,setReportLineSelected] = useState(null);
  let { periodId, globalBudgetId } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    fetchGlobalBudgetChart({
      period_id: periodId,
      overall_budget_id: globalBudgetId,
      ...selectedDates,
      ...filters,
    })
  }, [fetchGlobalBudgetChart, periodId, globalBudgetId, selectedDates, filters])

  useEffect(() => {
    if (tableKey !== TAB_KEYS.TAB_KEY_EVOLUTION) return

    if (tableId) {
      setIsEvolutionLoading(true)
      // TODO: NEW ENDPOINTS FOR REVAMP TABLE
      if (enabledEvolution) {
        fetchDataEvolutionByOverallBudget(tableId, parseInt(globalBudgetId), defaultPhase, {
          ...(auxCurrencyId && { currency_id: auxCurrencyId }),
        }).then(() => setIsEvolutionLoading(false))
      } else {
        fetchGlobalBudgetEvolutionTable(tableId, parseInt(globalBudgetId), defaultPhase, {
          ...selectedDates,
          ...filters,
        }).then(() => setIsEvolutionLoading(false))
      }
    }
  }, [tableId, globalBudgetId, defaultPhase, selectedDates, filters, auxCurrencyId, tableKey])

  useEffect(() => {
    if (tableKey !== TAB_KEYS.TAB_KEY_COMPARATION) return

    if (tableId) {
      setIsComparisonLoading(true)
      // TODO: NEW ENDPOINTS FOR REVAMP TABLE
      if (enabledComparative) {
        fetchDataComparativeByOverallBudget(tableId, parseInt(globalBudgetId), {
          ...(auxCurrencyId && { currency_id: auxCurrencyId }),
        }).then(() => setIsComparisonLoading(false))
      } else {
        fetchGlobalBudgetComparisonTable(tableId, parseInt(globalBudgetId), {
          ...selectedDates,
          ...filters,
        }).then(() => setIsComparisonLoading(false))
      }
    }
  }, [tableId, globalBudgetId, selectedDates, filters, auxCurrencyId, tableKey])

  useEffect(() => {
    setPeriodTableColumns(globalBudgetEvolutionTable?.columns)
  }, [globalBudgetEvolutionTable?.columns])

  const download = {
    evolution: () =>
      downloadGlobalBudgeEvolutiontXLS(
        t('PLANNING_GLOBAL_BUDGET_EVOLUTION_TABLE_FILE_NAME', {
          name: globalBudgetName,
          reportName: analysisList.values.find((el) => el.id === tableId).name,
          periodName: dataPeriod.name,
          date: moment().format('lll'),
        }),
        tableId,
        globalBudgetId,
        defaultPhase,
        { ...selectedDates, ...filters },
      ),
    comparison: () =>
      downloadGlobalBudgeComparisontXLS(
        t('PLANNING_GLOBAL_BUDGET_COMPARISON_TABLE_FILE_NAME', {
          name: globalBudgetName,
          reportName: analysisList.values.find((el) => el.id === tableId).name,
          periodName: dataPeriod.name,
          date: moment().format('lll'),
        }),
        tableId,
        globalBudgetId,
        { ...selectedDates, ...filters },
      ),
  }

  const getEvolutionTable = () => {
    if (enabledEvolution) {
      return (
        <EvolutionPivotTable
          setPeriodTableSettings={setPeriodTableSettings}
          periodTableSettings={periodTableSettings}
          download={download[tableKey]}
          setShowModal={setShowModal}
          globalProjectionId={globalBudgetId}
          allDimensionsList={allDimensionsList}
          isEvolutionLoading={isEvolutionLoading}
          tableLoading={tableLoading}
          reportId={tableId}
          onRefetch={() =>
            fetchDataEvolutionByOverallBudget(tableId, parseInt(globalBudgetId), defaultPhase, {
              ...(auxCurrencyId && { currency_id: auxCurrencyId }),
            })
          }
          setDataComments={setDataComments}
          dataComments={dataComments}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
        />
      )
    }
    return (
      <EvolutionTable
        reportId={tableId}
        rows={globalBudgetEvolutionTable.rows}
        columns={periodTableColumns}
        loading={isEvolutionLoading || tableLoading}
        settings={periodTableSettings}
        fetchEvolutionTableOpenRow={fetchEvolutionTableOpenRow}
        globalProjectionId={globalBudgetId}
        step={defaultPhase}
        selectedDates={selectedDates}
        filters={filters}
      />
    )
  }

  const getComparisonTable = () => {
    if (enabledComparative) {
      return (
        <ComparisonPivotTable
          setPeriodTableSettings={setPeriodTableSettings}
          periodTableSettings={periodTableSettings}
          download={download[tableKey]}
          setShowModal={setShowModal}
          globalProjectionId={globalBudgetId}
          allDimensionsList={allDimensionsList}
          isComparisonLoading={isComparisonLoading}
          tableLoading={tableLoading}
          reportId={tableId}
          onRefetch={() =>
            fetchDataComparativeByOverallBudget(tableId, parseInt(globalBudgetId), {
              ...(auxCurrencyId && { currency_id: auxCurrencyId }),
            })
          }
          setDataComments={setDataComments}
          dataComments={dataComments}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
        />
      )
    }
    return (
      <ComparisonTable
        globalProjectionComparisonTable={globalBudgetComparisonTable}
        fetchComparisonTableOpenRow={fetchComparisonTableOpenRow}
        loading={isComparisonLoading || tableLoading}
        reportId={tableId}
        selectedDates={selectedDates}
        filters={filters}
      />
    )
  }

  const tables = {
    evolution: getEvolutionTable(),
    comparison: getComparisonTable(),
  }

  const dataSpreadsheets = {
    ecolution: {
      type_load: 'evolutiva',
      params: {
        pk_report: tableId,
        pk_budget: globalBudgetId,
        step: defaultPhase,
      },
    },
    comparison: {
      type_load: 'comparativa',
      params: {
        pk_report: tableId,
        pk_budget: globalBudgetId,
      },
    },
  }

  const renderChart = () => {
    const renderChartTitle = () => (
      <>
        {t('REPORT_GRAPHIC_TITLE')}
        {/* <SelectGlobalProjectionLine  // comentado hasta ver si es posible hacerlo con back.
            reportLineSelected={reportLineSelected}
            setReportLineSelected={setReportLineSelected}
          /> */}
      </>
    )
    //TODO: VER SI EN ESTE CASO ES POSIBLE AGREGAR EL SELECTOR DE LINEAS
    return (
      <GlobalProjectionLineChart
        title={renderChartTitle()}
        exportTitle={`${t('REPORT_GRAPHIC_TITLE')} - ${t('LABEL_GLOBAL_BUDGET')}`}
        data={globalBudgetChart.asMutable({ deep: true })}
        setDataComments={setDataComments}
        dataComments={dataComments}
        onRefetchComments={onRefetchComments}
        loadingComments={loadingComments}
      />
    )
  }

  const renderTableAndActions = () => tables[tableKey]

  return (
    <>
      <Tabs defaultActiveKey={tableId} onChange={(key) => setTableId(key)} size="small">
        {analysisList.count > 0 &&
          analysisList.values
            .filter((report) => report.has_data)
            .map((report) => <Tabs.TabPane tab={report.name} key={report.id} />)}
      </Tabs>
      <Spin spinning={loading} />
      {tableId && !loading && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TableTypeSelect
                tableKey={tableKey}
                setTableKey={setTableKey}
                defaultPhase={defaultPhase}
                dataPhaseBudget={setPhaseAvailable(stepsBudgetInstance, dataPhaseBudget)}
                setDefaultPhase={setDefaultPhase}
              />
            </Col>
            <Col span={24}>{renderTableAndActions()}</Col>
            <Col span={24}>{renderChart()}</Col>
          </Row>
          <DownloadModal
            title={INTEGRATIONS.KEYS.SPREADSHEETS_KEY}
            visible={showModal}
            onCancel={() => setShowModal(false)}
            defaultValue={folder_id}
            dataSpreadsheets={dataSpreadsheets[tableKey]}
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  analysisList: reports.selectors.getAnalysisList(state),
  dataPhaseBudget: planning.selectors.getDataPhaseBudget(state),
  globalBudgetComparisonTable: planning.selectors.getGlobalBudgetComparisonTable(state),
  globalBudgetEvolutionTable: planning.selectors.getGlobalBudgetEvolutionTable(state),
  globalBudgetChart: planning.selectors.getGlobalBudgetChart(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchGlobalBudgetComparisonTable: planning.actions.fetchGlobalBudgetComparisonTable,
  fetchGlobalBudgetEvolutionTable: planning.actions.fetchGlobalBudgetEvolutionTable,
  fetchEvolutionTableOpenRow: planning.actions.fetchEvolutionTableOpenRow,
  fetchComparisonTableOpenRow: planning.actions.fetchComparisonTableOpenRow,
  fetchGlobalBudgetChart: planning.actions.fetchGlobalBudgetChart,
  downloadGlobalBudgeEvolutiontXLS: planning.actions.downloadGlobalBudgeEvolutiontXLS,
  downloadGlobalBudgeComparisontXLS: planning.actions.downloadGlobalBudgeComparisontXLS,
  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  fetchDataEvolutionByOverallBudget: planning.actions.fetchDataEvolutionByOverallBudget,
  fetchDataComparativeByOverallBudget: planning.actions.fetchDataComparativeByOverallBudget,
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsReports)
