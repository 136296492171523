import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Periods from './Periods/Periods'

const Home = () => (
  <Switch>
    <Route exact path="/periodos" component={Periods} />
    <Redirect to="/periodos" />
  </Switch>
)

export default Home
