import React, { useState } from 'react'
import { Form, Input, Modal, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { generateSelectOptions } from 'modules/core/utils'
import { connect } from 'react-redux'
import { PROJECTION } from 'modules/core/constants'
import { onCreate } from './utils'
import { useParams } from 'react-router-dom'
import forecast from 'modules/forecast'

const CreateForecastInstanceModal = ({
  visible,
  onCancel,
  optionsSelect,
  onRefetch,
  conceptId,
  createForecastInstance,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  let { forecastId } = useParams()

  const onclose = () => {
    form.resetFields()
    setIsLoading(false)
    onCancel()
  }

  const handleCreate = (values) => {
    const { name, overall } = values

    const id = parseInt(overall.split('_')[0])
    const type = overall.split('_')[1]
    const findOption = optionsSelect.find((el) => el.id === id && el.type === type)

    const data = {
      name,
      concept: conceptId,
      forecast_id: forecastId,
      ...(findOption && findOption.type === 'budget'
        ? {
            overall_budget: id,
          }
        : {
            forecast_overall_budget: id,
          }),
    }

    return createForecastInstance(data)
  }

  return (
    <Modal
      title={t('FORECAST_CREATE_MODAL_TITLE')}
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      centered
      forceRender
      visible={visible}
      onCancel={onCancel}
      onOk={() =>
        onCreate({
          form,
          setIsLoading,
          createForecastInstance: (values) => handleCreate(values),
          onRefetch,
          onclose,
        })
      }
      destroyOnClose={true}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t('FIELD_NAME')}
          name="name"
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
        >
          <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
        </Form.Item>
        <Form.Item
          name="overall"
          label={t('FORECAST_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL')}
          rules={[
            {
              required: true,
              message: t('REQUIRED_FIELD'),
            },
          ]}
        >
          <Select
            placeholder={t('ACTION_SELECT')}
            options={generateSelectOptions({
              options: optionsSelect.map((el) => {
                return {
                  id: `${el.id}_${el.type}`,
                  name: `${el.name}(${t(el.type)})-(${t(
                    PROJECTION.STATUS_COLORS[el.status].name,
                  )})`,
                }
              }),
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createForecastInstance: forecast.actions.createForecastInstance,
}

export default connect(null, mapDispatchToProps)(CreateForecastInstanceModal)
