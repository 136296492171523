import React from 'react'
import {
  ButtonActionBar,
  CreateGlobalProjectionModal,
  CustomBottomAction,
} from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { Input, Space } from 'antd'

const { Search } = Input

const UserActionsGlobal = ({
  globalBudgetApproved,
  periodId,
  onFetchGlobalBudgetList,
  title,
  globalText,
  role,
  createGlobalProjection,
  type,
  fetchProjectionInstanceList,
  projectionInstanceList,
  setSearchValue,
  module_name,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space direction="horizontal">
        <Search placeholder={t('ACTION_SEARCH')} allowClear={true} onSearch={setSearchValue} />
        <CreateGlobalProjectionModal
          globalBudgetApproved={globalBudgetApproved}
          periodId={periodId}
          title={title}
          globalText={globalText}
          role={role}
          onFetchGlobalBudgetList={onFetchGlobalBudgetList}
          text={t('LABEL_NEW').toLowerCase()}
          createGlobalProjection={createGlobalProjection}
          type={type}
          fetchProjectionInstanceList={fetchProjectionInstanceList}
          projectionInstanceList={projectionInstanceList}
        />
        <CustomBottomAction extraButtons={{ module_name: module_name }} />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActionsGlobal
