export const NAME = 'societies'

export const CREATE_SOCIETY = `${NAME}/CREATE_SOCIETY`

export const FETCH_SOCIETIES_LIST = `${NAME}/FETCH_SOCIETIES_LIST`
export const FETCH_SOCIETIES_LIST_SUCCESS = `${NAME}/FETCH_SOCIETIES_LIST_SUCCESS`
export const FETCH_SOCIETIES_LIST_FAIL = `${NAME}/FETCH_SOCIETIES_LIST_FAIL`

export const FETCH_SOCIETIES_DETAIL = `${NAME}/FETCH_SOCIETIES_DETAIL`
export const FETCH_SOCIETIES_DETAIL_SUCCESS = `${NAME}/FETCH_SOCIETIES_DETAIL_SUCCESS`
export const FETCH_SOCIETIES_DETAIL_FAIL = `${NAME}/FETCH_SOCIETIES_DETAIL_FAIL`

export const EDIT_SOCIETY = `${NAME}/EDIT_SOCIETY`

export const DELETE_SOCIETY = `${NAME}/DELETE_SOCIETY`

export const DOWNLOAD_SOCIETIES_XLS = `${NAME}/DOWNLOAD_SOCIETIES_XLS`

export const ACTIVATE_QUICKSIGHT = `${NAME}/ACTIVATE_QUICKSIGHT`

export const SET_USER_UPLOAD_S3 = `${NAME}/SET_USER_UPLOAD_S3`

export const ACTIVATE_ML = `${NAME}/ACTIVATE_ML`

export const FETCH_FUNCTIONS_BUTTONS_LIST = `${NAME}/FETCH_FUNCTIONS_BUTTONS_LIST`
export const FETCH_FUNCTIONS_BUTTONS_LIST_SUCCESS = `${NAME}/FETCH_FUNCTIONS_BUTTONS_LIST_SUCCESS`
export const FETCH_FUNCTIONS_BUTTONS_LIST_FAIL = `${NAME}/FETCH_FUNCTIONS_BUTTONS_LIST_FAIL`

export const FETCH_FUNCTIONS_BUTTONS_CREATE = `${NAME}/FETCH_FUNCTIONS_BUTTONS_CREATE`
export const FETCH_FUNCTIONS_BUTTONS_CREATE_SUCCESS = `${NAME}/FETCH_FUNCTIONS_BUTTONS_CREATE_SUCCESS`
export const FETCH_FUNCTIONS_BUTTONS_CREATE_FAIL = `${NAME}/FETCH_FUNCTIONS_BUTTONS_CREATE_FAIL`

export const FETCH_FUNCTIONS_BUTTONS_TENANT_LIST = `${NAME}/FETCH_FUNCTIONS_BUTTONS_TENANT_LIST`
export const FETCH_FUNCTIONS_BUTTONS_TENANT_LIST_SUCCESS = `${NAME}/FETCH_FUNCTIONS_BUTTONS_TENANT_LIST_SUCCESS`
export const FETCH_FUNCTIONS_BUTTONS_TENANT_LIST_FAIL = `${NAME}/FETCH_FUNCTIONS_BUTTONS_TENANT_LIST_FAIL`

export const FETCH_FUNCTIONS_BUTTONS_EXECUTE = `${NAME}/FETCH_FUNCTIONS_BUTTONS_EXECUTE`

export const CREATE_FORMAT = `${NAME}/CREATE_FORMAT`

export const FETCH_BUTTONS_STANDAR = `${NAME}/FETCH_BUTTONS_STANDAR`
export const FETCH_BUTTONS_STANDAR_SUCCESS = `${NAME}/FETCH_BUTTONS_STANDAR_SUCCESS`
export const FETCH_BUTTONS_STANDAR_FAIL = `${NAME}/FETCH_BUTTONS_STANDAR_FAIL`

export const EDIT_BUTTONS_STANDAR = `${NAME}/EDIT_BUTTONS_STANDAR`
export const DELETE_BUTTON_STANDAR = `${NAME}/DELETE_BUTTON_STANDAR`
