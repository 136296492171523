import ButtonDefault from '../../ButtonDefault'

const ButtonDefaultWhitRightIcon = ({
  title,
  icon = null,
  disabled = false,
  onClick = () => {},
  ...props
}) => {
  return (
    <ButtonDefault {...props} title={title} icon={icon} disabled={disabled} onClick={onClick} />
  )
}

export default ButtonDefaultWhitRightIcon
