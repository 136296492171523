import * as types from './constants'

export const uploadInitialFile =
  (fileList, data = {}) =>
  async (dispatch) => {
    const formData = new FormData()

    fileList.forEach((file) => {
      formData.append('files_data', file['originFileObj'])
    })
    data.tableData && formData.append('table_data', data.tableData)
    formData.append('type_file', data.type_file)

    const response = await dispatch({
      type: types.UPLOAD_INITIAL_FILE,
      payload: {
        request: {
          url: '/core/upload_file/',
          method: 'POST',
          data: formData,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const saveConfig =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.SAVE_CONFIG,
      payload: {
        request: {
          url: '/core/save_config/',
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const setDataConnectors = (data) => (dispatch) =>
  dispatch({
    type: types.SET_DATA_CONNECTORS,
    payload: data,
  })

export const deleteFile = (id) => (dispatch) =>
  dispatch({
    type: types.DELETE_FILE,
    payload: id,
  })

export const setFilesData = (data) => (dispatch) =>
  dispatch({
    type: types.SET_FILE_DATA,
    payload: data,
  })

export const setCleanData = (data) => (dispatch) =>
  dispatch({
    type: types.SET_CLEAN_DATA,
    payload: data,
  })

export const setDataSelect = (data) => (dispatch) =>
  dispatch({
    type: types.SET_DATA_SELECT,
    payload: data,
  })

export const setSelection = (data) => (dispatch) => {
  dispatch({
    type: types.SET_SELECTION,
    payload: data,
  })
}

export const deleteSelection = (data) => (dispatch) =>
  dispatch({
    type: types.DELETE_SELECTION,
    payload: data,
  })

export const addNewSelection = (data) => (dispatch) =>
  dispatch({
    type: types.ADD_NEW_SELECTION,
    payload: data,
  })

export const connectERP =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CONNECT_ERP,
      payload: {
        request: {
          url: '/integrations/connector/',
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchStatusConnection =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_STATUS_CONNECTON,
      payload: {
        request: {
          url: `/integrations/connector/`,
          method: 'GET',
          params,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const downloadSpreadsheets =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_SPREADSHEETS,
      payload: {
        request: {
          url: '/integrations/download/',
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updateSpreadsheets =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_SPREADSHEETS,
      payload: {
        request: {
          url: '/integrations/download/',
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const uploadSpreadsheets =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPLOAD_SPREADSHEETS,
      payload: {
        request: {
          url: '/integrations/upload/',
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const uploadFileTest =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPLOAD_FILE_TEST,
      payload: {
        request: {
          url: '/core/generate_presigned_url/',
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

//dashboard

export const fetchBudgetTypesCards =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_BUDGET_TYPE_CARDS,
      payload: {
        request: {
          url: `/dashboard/get_cards_ppto/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchReportsCards =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_REPORTS_CARDS,
      payload: {
        request: {
          url: `/dashboard/get_cards_report/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataProjectionChart =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_PROJECTION_CHART,
      payload: {
        request: {
          url: `/dashboard/graph_kpis_projection/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataBudgetTypeChart =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_BUDGET_TYPE_CHART,
      payload: {
        request: {
          url: `/dashboard/graph_ppto_types/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataDimensionChart =
  (id, periodId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_DIMENSION_CHART,
      payload: {
        request: {
          url: `/dashboard/pie_chard_dimensions/${id}/${periodId}/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }
