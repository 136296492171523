import React from 'react'
import { Input, Space } from 'antd'
import { Button, ButtonActionBar } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const GeneralActions = ({
  setSearchValue,
  setShowCreateModal,
  userCanCreate,
  isSuperuser = false,
  onClickConfigurate = null,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="user-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={(search) => setSearchValue(search)}
        />
        <Button.Primary
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
          title="ACTION_CREATE_NEW"
        />
        {isSuperuser && <Button.Default title="ACTION_CONFIGURATE" onClick={onClickConfigurate} />}
      </Space>
    </ButtonActionBar>
  )
}

export default GeneralActions
