import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Col, Row, Input, DatePicker, Modal } from 'antd'
import planning from 'modules/planning'
import { onCreatePeriod } from './utils'

const { RangePicker } = DatePicker

const FormItem = Form.Item

const PeriodModal = ({ visible, onClose, createPeriod, onFetchPeriods }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onCancel = () => {
    form.resetFields()
    setIsLoading(false)
    onClose()
  }

  return (
    <Modal
      title={t('PERIOD_CREATE_MODAL_TITLE')}
      visible={visible}
      onCancel={!isLoading ? onCancel : null}
      destroyOnClose={true}
      centered
      okText={t('PERIOD_CREATE_ACTION')}
      cancelText={t('ACTION_CANCEL')}
      onOk={() =>
        onCreatePeriod({
          form,
          createPeriod,
          setIsLoading,
          onRefetch: onFetchPeriods,
          onClose: onCancel,
        })
      }
      confirmLoading={isLoading}
      okButtonProps={{ disabled: isLoading }}
    >
      <Form form={form} layout="vertical" hideRequiredMark={false}>
        <Row gutter={24}>
          <Col span={10}>
            <FormItem
              name="name"
              label={t('PERIOD_CREATE_MODAL_NAME_LABEL')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('PERIOD_CREATE_MODAL_NAME_PLACEHOLDER')} />
            </FormItem>
          </Col>
          <Col span={14}>
            <FormItem
              name="range_date"
              label={t('PERIOD_CREATE_MODAL_DATES_LABEL')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <RangePicker
                picker="month"
                format="MMM YYYY"
                placeholder={[t('FIELD_START_DATE'), t('FIELD_END_DATE')]}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createPeriod: planning.actions.createPeriod,
}

export default connect(null, mapDispatchToProps)(PeriodModal)
