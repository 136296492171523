import { useFeatureIsOn } from '@growthbook/growthbook-react'
import reports from 'modules/reports'
import { ReportLineChart } from 'modules/reports/components'
import React from 'react'
import { connect } from 'react-redux'
import { ChartTitle } from './components'
import { setChartTitle, setReportLineName } from './utils'

const ReportChart = ({
  rows,
  lineId,
  selectedDates,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  reportChartData,
  getDataGraphByReport,
}) => {
  // Feature flag
  const enabled = useFeatureIsOn('feature-react-pivot-evolution')

  const getData = () => {
    if (enabled) {
      return getDataGraphByReport
    }
    return reportChartData
  }

  return (
    <ReportLineChart
      title={<ChartTitle rows={rows} lineId={lineId} selectedDates={selectedDates} />}
      chartTitle={`${setChartTitle(selectedDates)} ${setReportLineName({ rows, lineId })}`}
      data={[getData()]}
      setDataComments={setDataComments}
      dataComments={dataComments}
      onRefetchComments={onRefetchComments}
      loadingComments={loadingComments}
      commentKey={commentKey}
    />
  )
}

const mapStateToProps = (state) => ({
  reportChartData: reports.selectors.getReportChartData(state),
  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  getDataGraphByReport: reports.selectors.getDataGraphByReport(state),
})

export default connect(mapStateToProps, null)(ReportChart)
