import React from 'react'
import { Input, Space } from 'antd'
import { ButtonActionBar, CreateReportModal } from 'modules/core/components'
import { ROLES } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'

const { Search } = Input
const { SETTINGS__REPORTS__REPORTSPNL__ABM } = ROLES

const UserActions = ({ onRefetch, setSearchValue, onClickSpreadsheets }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="configuration-reports-list-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={(search) => setSearchValue(search)}
        />
        <CreateReportModal
          rolesAvailables={[SETTINGS__REPORTS__REPORTSPNL__ABM]}
          onFetchReportList={() => onRefetch()}
          onClickSpreadsheets={onClickSpreadsheets}
        />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActions
