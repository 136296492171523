import React from 'react'
import { Input } from 'antd'
import { Button, ButtonActionBar } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const UserActions = ({ setSearchValue, onClickUpload, disabled }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Search
        className="configuration-reports-detail-search"
        placeholder={t('ACTION_SEARCH')}
        allowClear={true}
        onSearch={setSearchValue}
      />
      <Button.Primary onClick={onClickUpload} disabled={disabled} title="ACTION_UPLOAD_FILE" />
    </ButtonActionBar>
  )
}

export default UserActions
