import { useState } from 'react'
import { Col, message, Row } from 'antd'
import { omitBy } from 'lodash'
import { ButtonActionBar, Comments, PercentageTable, Typography } from 'modules/core/components'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CommentOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { handleApply, handleCellEdit, handleUnApply } from './utils'
import planning from '../../../../../../index'
import ActionBar from './components/ActionBar'
import configuration from 'modules/configuration'
import login from 'modules/login'
import { generateMessageError } from 'modules/core/utils'

const Percentage = (props) => {
  const {
    loading,
    dataPhase,
    budgetInstance,
    dataRdnPercentagePlanning,
    currentRdnSelect,
    businessRulesOption,
    handleRdnChange,
    updateTransactionPercentage,
    reloadTablePercentage,
    applyRdnTransactionPercentage,
    unApplyRdnTransactionPercentage,
    setData,
    comments,
    onRefetchComments,
    loadingComments,
    loggedUser,
    setHasComment,
    hasComment,
    createComment,
    removeNewComment,
  } = props
  // Hooks
  const { budgetId, periodId } = useParams()
  // State
  const [settings, setSettings] = useState({})
  const [viewComment, setViewComment] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()
  // Values
  const baseDataSend = {
    concept_id: budgetInstance?.concept_id,
    period_id: periodId,
    step: dataPhase.key,
  }
  const key = window.location.pathname.replaceAll('/', '-')

  const normalizedData = (rows) => {
    const filters = {
      ...rows,
      date: rows.Fecha,
      Fecha: undefined,
    }
    return omitBy(filters, (prop) => prop == null)
  }

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-${dataPhase.key}-table-percentage`,
      module: `${key}-${dataPhase.key}`,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  const renderTableActions = () => (
    <ButtonActionBar>
      {(viewComment ||
        comments.filter(
          (el) => el.key === `${key}-${dataPhase.key}-table-percentage` && !el.resolved,
        ).length > 0) && (
        <Comments
          hasComment={hasComment.table}
          onClick={(visible) => {
            setHasComment({ type: 'table', data: visible })
            !visible && removeNewComment()
            !visible && setViewComment(visible)
          }}
          comments={comments.filter(
            (el) =>
              (el.key === `${key}-${dataPhase.key}-table-percentage` && !el.resolved) || !el.text,
          )}
          setData={setData}
          loading={loadingComments || isSaving}
          onRefetchComments={onRefetchComments}
          onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
            handleCreate({ text, usersIds, idComment, setIsNewThread })
          }
          onCleanHasComment={() => {
            setHasComment({ type: 'table', data: false })
            setViewComment(false)
          }}
        >
          <Typography.Icon
            style={{ cursor: 'pointer' }}
            title={t('LABEL_COMMENTS')}
            icon={CommentOutlined}
          />
        </Comments>
      )}
    </ButtonActionBar>
  )

  return (
    <Row gutter={[4, 4]}>
      <Col span={24}>
        <ActionBar
          rdnDefault={currentRdnSelect}
          rdnOptions={businessRulesOption}
          handleRdnChange={(value) => handleRdnChange(value)}
          onApplyConfirmPopConfirm={() =>
            handleApply({
              applyRdnPercentage: () =>
                applyRdnTransactionPercentage(currentRdnSelect, budgetId, baseDataSend),
              onRefetch: reloadTablePercentage,
            })
          }
          onUnApplyConfirmPopConfirm={() =>
            handleUnApply({
              unApplyRdnPercentage: () =>
                unApplyRdnTransactionPercentage(currentRdnSelect, budgetId, baseDataSend),
              onRefetch: reloadTablePercentage,
            })
          }
        />
        <br />
      </Col>
      <Col span={24}>{renderTableActions()}</Col>
      <Col span={24}>
        <PercentageTable
          loading={loading}
          settings={settings}
          setSettings={setSettings}
          dataPercentageTable={dataRdnPercentagePlanning}
          onCellEdit={(amount, row, type_change) => {
            const dataSend = {
              ...baseDataSend,
              amount,
              row: normalizedData(row),
              type_change,
              planning_id: budgetId,
              business_rule_id: currentRdnSelect,
            }
            handleCellEdit({
              updateTransactionPercentage: () => updateTransactionPercentage(dataSend),
              onRefetch: reloadTablePercentage,
            })
          }}
          hasComment={hasComment}
          setHasComment={setHasComment}
          setViewComment={setViewComment}
        />
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  dataRdnPercentagePlanning: planning.selectors.getDataRdnPercentagePlanning(state),
  hasComment: configuration.selectors.getHasComment(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  updateTransactionPercentage: planning.actions.updateTransactionPercentageByPlanning,
  applyRdnTransactionPercentage: planning.actions.applyRdnPercentageByPlanning,
  unApplyRdnTransactionPercentage: planning.actions.unApplyRdnPercentageByPlanning,
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(Percentage)
