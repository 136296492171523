import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { usePagination } from 'modules/core/customHooks'
import {
  EmptyScreen,
  CreateUserModal,
  GeneralActions,
  UserTableList,
  Button,
} from 'modules/core/components'
import { isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import { TableActions } from './components'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import configuration from 'modules/configuration'
import { handleDelete } from './utils'
import moment from 'moment/moment'

const { SETTINGS__SECURITY__USERS__ABM } = ROLES

const UsersList = ({
  loading,
  usersList: { count, values },
  fetchUsersList,
  deleteUsersSelections,
  downloadUserList,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [searchValue, setSearchValue] = useState(null)
  const [tablePagination, setTablePagination] = usePagination()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [dataSelection, setDataSelection] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    setIsTableLoading(true)
    fetchUsersList({ search: searchValue, ...tablePagination }).then(() => {
      setIsTableLoading(false)
      setIsScreenLoading(false)
    })
  }, [searchValue, tablePagination, fetchUsersList])

  const userCanCreate = isUserAllowed(SETTINGS__SECURITY__USERS__ABM)

  const onRefetch = () => fetchUsersList({ search: searchValue, ...tablePagination })

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_USERS_EMPTY_STATE_TITLE')}
      footer={
        <Button.Primary
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
          title="ACTION_CREATE_NEW"
        />
      }
    />
  )

  const renderTable = () => (
    <UserTableList
      isLoading={isTableLoading || isDeleting}
      dataSource={values}
      dataSelection={dataSelection}
      setDataSelection={setDataSelection}
      canDelete={userCanCreate}
      onTableChange={setTablePagination}
      pagination={{
        pageSize: tablePagination.page_size,
        current: tablePagination.page,
        total: count,
      }}
      onRefetch={() => onRefetch()}
    />
  )

  const renderUserActions = () => (
    <GeneralActions
      setSearchValue={setSearchValue}
      setShowCreateModal={setShowCreateModal}
      userCanCreate={userCanCreate}
    />
  )

  const onClose = () => {
    setIsDeleting(false)
    setDataSelection([])
  }

  const renderTableActions = () => (
    <TableActions
      dataSelection={dataSelection}
      onConfirmDelete={() =>
        handleDelete({
          setIsLoading: setIsDeleting,
          deleteUsersSelections: () => deleteUsersSelections({ users: dataSelection }),
          onRefetch,
          onClose,
        })
      }
      downloadUserList={() =>
        downloadUserList(
          t('CONFIG_USER_LIST_FILE_NAME', {
            date: moment().format('lll'),
          }),
        )
      }
      disabled={!userCanCreate}
      isDeleting={isDeleting}
    />
  )

  return (
    <>
      <Spin spinning={isScreenLoading || loading} />
      <Row gutter={[8, 8]}>
        {count <= 0 && !searchValue && !loading && !isTableLoading && (
          <Col span={24}>{renderNoDataScreen()}</Col>
        )}
        {(count > 0 || (searchValue && count === 0)) && (
          <>
            <Col span={24}>{renderUserActions()}</Col>
            <Col span={24}>{renderTableActions()}</Col>
            <Col span={24}>{renderTable()}</Col>
          </>
        )}
        <CreateUserModal
          visible={showCreateModal}
          onCancel={() => setShowCreateModal(false)}
          onRefetch={() => onRefetch()}
        />
      </Row>
    </>
  )
}

const mapStateToProps = (state) => ({
  usersList: configuration.selectors.getUsersList(state),
})

const mapDispatchToProps = {
  fetchUsersList: configuration.actions.fetchUsersList,
  fetchUserTemplateXLS: configuration.actions.fetchUserTemplateXLS,
  fetchUserConsolidatedXLS: configuration.actions.fetchUserConsolidatedXLS,
  uploadUsersXLS: configuration.actions.uploadUsersXLS,
  deleteUsersSelections: configuration.actions.deleteUsersSelections,
  downloadUserList: configuration.actions.downloadUserList,
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersList)
