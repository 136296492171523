import React from 'react'
import { Space, Input } from 'antd'
import { Button, ButtonActionBar } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const UserActions = ({ setSearchValue, onClick, onClickDownload, onClickSettings, disabled }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="dimension-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={setSearchValue}
        />
        <Button.Primary onClick={onClick} disabled={disabled} title="ACTION_CREATE_NEW" />
        <Button.Default onClick={onClickDownload} disabled={disabled} title="ACTION_UPLOAD_FILE" />
        <Button.Default
          title="CONFIG_SETTINGS_MODAL_TITLE"
          onClick={onClickSettings}
          disabled={disabled}
        />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActions
