import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { TopTableActions } from 'modules/core/components'
import {
  handleSave,
  handleSavePrimary,
} from 'modules/control/containers/ControlDetail/components/Evolution/utils'
import { Dropdown, Menu, message, Spin } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import PivotTableUX from '../../../../../../../components/PivotTable/PivotTableUX'
import TableRenderers from '../../../../../../../components/PivotTable/Renderers/Evolution/TableRenderers'
import planning from '../../../../../index'
import control from 'modules/control'
import configuration from 'modules/configuration'
import login from 'modules/login'
import _ from 'lodash'
import { handleDelete } from '../utils'
import PlaneTableUX from 'components/PivotTable/PlaneTableUX'
import PlaneTableRenderers from 'components/PivotTable/Renderers/Evolution/Plane'
import { useTranslation } from 'react-i18next'
import { generateMessageError } from 'modules/core/utils'
import { GENERAL } from 'modules/core/constants'

const DEFAULT_FORMAT_CREATE = {
  Decimales: 'float',
  Enteros: 'int',
  Miles: 't',
  Millones: 'm',
}

const DEFAULT_FORMAT_SET = {
  float: 'Decimales',
  int: 'Enteros',
  t: 'Miles',
  m: 'Millones',
}

const getRowAuxName = (rows, allDimensionsList) => {
  return rows.map((row) => {
    if (row === 'Linea de reporte') {
      return 'Linea de reporte'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(row) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getColsAuxName = (columns, allDimensionsList) => {
  return columns.map((col) => {
    if (col === 'Fecha') {
      return 'Fecha'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(col) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getRowAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.rows.map((row) => {
      if (row === 'Linea de reporte') {
        return 'Linea de reporte'
      } else {
        const id = allDimensionsList.find((el) => el.name_origin === row).id
        return id.toString()
      }
    })
  )
}

const getColsAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.cols.map((col) => {
      const id = allDimensionsList.find((el) => el.name_origin === col)
        ? allDimensionsList.find((el) => el.name_origin === col).id
        : null
      if (col === 'Fecha') {
        return 'Fecha'
      } else {
        return id.toString()
      }
    })
  )
}

const EvolutionPivotTable = ({
  setPeriodTableSettings,
  periodTableSettings,
  download,
  setShowModal,
  globalProjectionId,
  allDimensionsList,
  isEvolutionLoading,
  tableLoading,
  reportId,
  onRefetch,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  dataEvolutionBudgetReport,
  fetchLayoutList,
  layoutList,
  createLayout,
  deleteLayout,
  hasComment,
  setHasComment,
  loggedUser,
  createComment,
  removeNewComment,
}) => {
  const enabledPlaneTable = useFeatureIsOn('feature-plane-table-custom')
  const [settings, setSettings] = useState({})
  const [showPopover, setShowPopover] = useState(false)
  const [favouriteName, setFavouriteName] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isLayoutLoading, setIsLayoutLoading] = useState(false)
  const [viewSelect, setViewSelect] = useState({})
  const [viewComment, setViewComment] = useState(false)
  const [isCommentSaving, setIsCommentSaving] = useState(false)
  const { dataSource, fields, linesOrder, linesTotal, linesRatio, linesRatioN, linesFinancial } =
    dataEvolutionBudgetReport
  const { t } = useTranslation()

  const key = window.location.pathname.replaceAll('/', '-')

  useEffect(() => {
    setSettings((prevState) => {
      return { ...prevState, data: dataSource }
    })
  }, [dataSource])

  useEffect(() => {
    setIsLayoutLoading(true)
    fetchLayoutList('overallbudget', `${globalProjectionId}-${reportId}-montly_evolution`).then(
      () => {
        setIsLayoutLoading(false)
        setSettings({})
      },
    )
  }, [fetchLayoutList, globalProjectionId, reportId])

  useEffect(() => {
    const defaultLayout = layoutList.values.find((el) => el.primary)
    setViewSelect(defaultLayout)
  }, [layoutList.values])

  useEffect(() => {
    if (layoutList.values.length > 0 && !_.isEmpty(viewSelect) && allDimensionsList.length > 0) {
      setSettings((prevState) => {
        return {
          ...prevState,
          ...viewSelect.config,
          cols:
            getColsAuxName(viewSelect.config.columns, allDimensionsList).length > 0
              ? getColsAuxName(viewSelect.config.columns, allDimensionsList)
              : ['Fecha'],
          rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
          aggregatorName: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
          vals: [viewSelect.config.default_value],
        }
      })
    }
  }, [allDimensionsList, layoutList.values, viewSelect])

  const onClose = () => {
    setIsSaving(false)
    setShowPopover(false)
    setFavouriteName('')
  }

  const onCreate = () => {
    const data = {
      row_order: 0,
      column_order: 1,
      default_value: settings.vals[0],
      default_format: DEFAULT_FORMAT_CREATE[settings.aggregatorName],
      rows: getRowAuxId(settings, allDimensionsList),
      columns: getColsAuxId(settings, allDimensionsList),
    }

    return createLayout(
      'overallbudget',
      `${globalProjectionId}-${reportId}-montly_evolution`,
      favouriteName,
      { config: data },
    )
  }

  const defaultData = !_.isEmpty(viewSelect) && {
    ...viewSelect.config,
    rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
    columns: getColsAuxName(viewSelect.config.columns, allDimensionsList),
    default_format: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
  }
  const defaultSettings = !_.isEmpty(settings) && {
    rows: settings.rows,
    columns: settings.cols || ['Fecha'],
    row_order: settings.row_order,
    column_order: settings.column_order,
    default_value: settings.vals ? settings.vals[0] : '',
    default_format: settings.aggregatorName,
  }

  const onSavePrimary = (data) => {
    const dataAux = layoutList.values.find((el) => el.name === data.name)
    return createLayout(
      'overallbudget',
      `${globalProjectionId}-${reportId}-montly_evolution`,
      dataAux.name,
      { ...dataAux, primary: true },
    )
  }

  const renderTable = () => {
    if (enabledPlaneTable) {
      return (
        <Dropdown
          trigger={['contextMenu']}
          overlay={
            <Menu>
              {GENERAL.OPTION_COMMENT.map((el) => (
                <Menu.Item
                  key={el.key}
                  icon={<CommentOutlined />}
                  onClick={() => {
                    setViewComment(true)
                    setHasComment({ type: 'table', data: !hasComment.table })
                  }}
                >
                  {t(el.label)}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <div style={{ width: '100%' }}>
            <PlaneTableUX
              renderers={Object.assign({}, PlaneTableRenderers)}
              data={dataSource}
              onChange={(s) => setSettings(s)}
              vals={settings.default_value ? [settings.default_value] : ['amount']}
              cols={fields.columns}
              rows={['Linea de reporte']}
              aggregatorName={settings.default_format || 'Decimales'}
              hiddenFromDragDrop={['amount', 'price', 'quantity']}
              hiddenFromAggregators={
                dataSource[0] &&
                dataSource[0].filter((el) => el !== 'amount' && el !== 'price' && el !== 'quantity')
              }
              tableOptions={{
                linesOrder: linesOrder,
                linesTotal: linesTotal,
                linesRatio: linesRatio,
                linesRatioN: linesRatioN,
                linesFinancial: linesFinancial,
                lineVertical: null,
              }}
              tableFilter={{}}
              plotlyConfig={{
                responsive: true,
              }}
              {...settings}
            />
          </div>
        </Dropdown>
      )
    }
    return (
      <Dropdown
        trigger={['contextMenu']}
        overlay={
          <Menu>
            {GENERAL.OPTION_COMMENT.map((el) => (
              <Menu.Item
                key={el.key}
                icon={<CommentOutlined />}
                onClick={() => {
                  setViewComment(true)
                  setHasComment({ type: 'table', data: !hasComment.table })
                }}
              >
                {t(el.label)}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <div style={{ width: '100%' }}>
          <PivotTableUX
            renderers={Object.assign({}, TableRenderers)}
            data={dataSource}
            onChange={(s) => setSettings(s)}
            vals={settings.default_value ? [settings.default_value] : ['amount']}
            cols={fields.columns}
            rows={['Linea de reporte']}
            aggregatorName={settings.default_format || 'Decimales'}
            hiddenFromDragDrop={['amount', 'price', 'quantity']}
            hiddenFromAggregators={
              dataSource[0] &&
              dataSource[0].filter((el) => el !== 'amount' && el !== 'price' && el !== 'quantity')
            }
            tableOptions={{
              linesOrder: linesOrder,
              linesTotal: linesTotal,
              linesRatio: linesRatio,
              linesRatioN: linesRatioN,
              linesFinancial: linesFinancial,
              lineVertical: null,
            }}
            tableFilter={{}}
            plotlyConfig={{
              responsive: true,
            }}
            {...settings}
          />
        </div>
      </Dropdown>
    )
  }

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-table-evolution-${reportId}`,
      module: key,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsCommentSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsCommentSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsCommentSaving(false)
        setIsNewThread(false)
      })
  }

  return (
    <>
      <TopTableActions
        setPeriodTableSettings={setPeriodTableSettings}
        periodTableSettings={periodTableSettings}
        onClickDownload={download}
        onClickDownloadSpreadsheets={() => setShowModal(true)}
        onClickSaveFav={() => setShowPopover(true)}
        visibleFav={showPopover}
        onClickSave={() =>
          handleSave({
            setIsLoading: setIsSaving,
            createLayout: () => onCreate(),
            onRefetch: () =>
              fetchLayoutList(
                'overallbudget',
                `${globalProjectionId}-${reportId}-montly_evolution`,
              ),
            onRefresh: onRefetch,
            onClose,
          })
        }
        onClickCancelSaveFav={onClose}
        setFavouriteName={setFavouriteName}
        favouriteName={favouriteName}
        isLoading={isSaving}
        hasFavourite={layoutList.values.length > 0}
        options={layoutList.values.map((el) => {
          return { name: el.name, id: el.name, primary: el.primary }
        })}
        canSave={
          !_.isEmpty(settings) || (!_.isEmpty(settings) && !_.isEqual(defaultData, defaultSettings))
        }
        viewSelect={viewSelect}
        setViewSelect={(val) => {
          const aux = layoutList.values.find((el) => el.name === val)
          setViewSelect(aux)
        }}
        alreadyExist={_.isEqual(defaultData, defaultSettings)}
        layoutLoading={isLayoutLoading}
        setPrimary={(data) =>
          handleSavePrimary({
            setIsLoading: setIsSaving,
            createLayout: () => onSavePrimary(data),
            onRefetch: () =>
              fetchLayoutList(
                'overallbudget',
                `${globalProjectionId}-${reportId}-montly_evolution`,
              ),
            onClose: () => setIsSaving(false),
          })
        }
        onClickDeleteFav={(name) =>
          handleDelete({
            setIsLoading: setIsSaving,
            deleteLayout: () =>
              deleteLayout(
                'overallbudget',
                `${globalProjectionId}-${reportId}-montly_evolution`,
                name,
              ),
            onRefetch: () =>
              fetchLayoutList(
                'overallbudget',
                `${globalProjectionId}-${reportId}-montly_evolution`,
              ),
            onClose: () => setIsSaving(false),
          })
        }
        showComments={
          viewComment ||
          dataComments.filter(
            (el) => el.key === `${key}-table-evolution-${reportId}` && !el.resolved,
          ).length > 0
        }
        hasComment={hasComment}
        setHasComment={setHasComment}
        setViewComment={setViewComment}
        userDetail={loggedUser}
        settings={settings}
        setDataComments={setDataComments}
        dataComments={dataComments.filter(
          (el) => (el.key === `${key}-table-evolution-${reportId}` && !el.resolved) || !el.text,
        )}
        onRefetchComments={onRefetchComments}
        loadingComments={loadingComments}
        onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
          handleCreate({ text, usersIds, idComment, setIsNewThread })
        }
        isCommentSaving={isCommentSaving}
        removeNewComment={removeNewComment}
      />
      <Spin spinning={isEvolutionLoading || tableLoading}>{renderTable()}</Spin>
    </>
  )
}

const mapStateToProps = (state) => ({
  dataEvolutionBudgetReport: planning.selectors.getDataEvolutionBudgetReport(state),
  layoutList: control.selectors.getLayoutList(state),
  hasComment: configuration.selectors.getHasComment(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  createLayout: control.actions.createLayout,
  fetchLayoutList: control.actions.fetchLayoutList,
  deleteLayout: control.actions.deleteLayout,
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(EvolutionPivotTable)
