import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Space, Spin, Tooltip } from 'antd'
import { SiderLayout } from 'modules/core/layouts'
import {
  ActionModal,
  Button,
  DateAndDimensionFilters,
  KPIsGlobalDetail,
  PeriodNavigator,
  ProjectionSelects,
  ScreenWellDone,
  ToolsDrawer,
  UserActionsGlobalDetail,
} from 'modules/core/components'
import { TabsReports } from './components'
import { useTranslation } from 'react-i18next'
import {
  getBudgetSelected,
  getOperationsByKeys,
  getStepsOfInstances,
  isApproved,
  isPublished,
  isUserAllowed,
} from 'modules/core/utils'
import { ROLES, PROJECTION, GENERAL } from 'modules/core/constants'
import { client } from 'httpclient'
import { onSetDefaultForecast } from './utils'
import { useDefaultCurrency } from 'modules/core/customHooks'
import imageDefault from 'assets/images/compositions/feedback-image.svg'
import reports from 'modules/reports'
import planning from 'modules/planning'
import configuration from 'modules/configuration'
import forecast from 'modules/forecast'
import moment from 'moment'

import './GlobalForecastDetail.scss'

const { FORECAST__GLOBAL_FORECAST__ABM, SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES } = ROLES

const GlobalForecastDetail = ({
  globalForecastDetail,
  fetchBudgetTypeList,
  analysisList,
  fetchAnalysisList,
  approveGlobalForecast,
  setDefaultForecast,
  publishGlobalForecast,
  fetchGlobalForecastDetail,
  periodList,
  changeProjectionForecast,
  fetchDataEvolutionByOverallForecast,
  fetchDataComparativeByOverallForecast,
  applyRuleForecast,
  unApplyRuleForecast,
  fetchAllDimensions,
  allDimensionsList,
  fetchCurrencyList,
  currencyList,
  fetchCommentsList,
  commentsList,
  fetchForecasts,
  forecasts,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [isBudgetLoading, setIsBudgetLoading] = useState(false)
  const [tableId, setTableId] = useState(null)
  const [showActionModal, setShowActionModal] = useState(null)
  const [showScreenSuccess, setShowScreenSuccess] = useState(null)
  const [options, setOptions] = useState([])
  const [selectedDates, setSelectedDates] = useState({})
  const [filters, setFilters] = useState({})
  const [showDrawerRules, setShowDrawerRules] = useState(false)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [defaultPhase, setDefaultPhase] = useState(PROJECTION.BASE_KEY)
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(false)
  const [currencyChange, setCurrencyChange] = useState(null)
  const [dataComments, setDataComments] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)

  const defaultCurrencyData = useDefaultCurrency()
  let { periodId, forecastId, globalForecastId } = useParams()
  const { t } = useTranslation()

  const auxCurrencyId = currencyChange ? currencyChange : defaultCurrencyData?.id
  const key = window.location.pathname.replaceAll('/', '-')

  useEffect(() => {
    setIsLoadingComments(true)
    fetchCommentsList({ module: key }).then(() => setIsLoadingComments(false))
  }, [fetchCommentsList, key])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    setIsScreenLoading(true)
    fetchGlobalForecastDetail(globalForecastId).then(() => setIsScreenLoading(false))
  }, [fetchGlobalForecastDetail, globalForecastId])

  useEffect(() => {
    fetchAnalysisList().then((data) => {
      data.payload.data.length > 0 && setTableId(data.payload.data[0].id)
    })
  }, [fetchAnalysisList])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  useEffect(() => {
    fetchForecasts({ period_id: periodId })
  }, [fetchForecasts, periodId])

  //todo: revisar
  useEffect(() => {
    fetchBudgetTypeList().then((response) => {
      response.payload.data.concepts.forEach((concept) => {
        setIsBudgetLoading(true)
        client.get(`/planning/forecast/get_kpis/${forecastId}/${concept.id}/`).then((resp) => {
          const aux = resp.data.map((budget) => {
            return { ...budget, conceptId: concept.id }
          })
          setOptions((prevState) => [...prevState, ...aux])
          setIsBudgetLoading(false)
        })
      })
    })
  }, [fetchBudgetTypeList, forecastId])

  useEffect(() => {
    setIsCurrencyLoading(true)
    fetchCurrencyList().then(() => {
      setIsCurrencyLoading(false)
    })
  }, [fetchCurrencyList])

  const isGlobalBudgetApproved = isApproved(globalForecastDetail?.status)
  const isGlobalBudgetPublished = isPublished(globalForecastDetail?.status)
  const isActionApprove = isApproved(showActionModal)
  const userCanAction = isUserAllowed(FORECAST__GLOBAL_FORECAST__ABM)
  const userCanViewAndABMRules = isUserAllowed(
    getOperationsByKeys([SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES]),
  )
  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))
  const notForecastClosed = getBudgetSelected(globalForecastDetail?.budgets_ids, options)
    ? getBudgetSelected(globalForecastDetail?.budgets_ids, options).some((el) => !el?.is_closing)
    : true

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_FORECAST'),
          url: `/forecast/${periodId}`,
        },
        {
          name: `${t('LABEL_DETAIL')}: ${t('LABEL_FORECAST')}`,
          url: `/forecast/${periodId}/${forecastId}?tab=${PROJECTION.GLOBAL_FORECAST}`,
        },
        { name: globalForecastDetail?.name },
      ]}
    />
  )

  const renderSelectors = () => (
    <ProjectionSelects
      loading={isBudgetLoading}
      idsBudgetsSelected={globalForecastDetail?.budgets_ids}
      disabled={isGlobalBudgetApproved}
      options={options}
      globalProjectionId={globalForecastId}
      changeProjection={changeProjectionForecast}
      onRefetchGobalBudgetDetail={() => fetchGlobalForecastDetail(globalForecastId)}
    />
  )

  const renderUserActions = () => (
    <UserActionsGlobalDetail
      onClickPublish={() => setShowActionModal(PROJECTION.STATUS.PUBLISHED)}
      disabledPublish={isGlobalBudgetPublished || isGlobalBudgetApproved || !userCanAction}
      buttonPublishName={isGlobalBudgetPublished ? t('STATE_TAG_PUBLISHED') : t('ACTION_PUBLISH')}
      tooltipApprove={
        !isGlobalBudgetPublished && !isGlobalBudgetApproved ? t('TOOLTIP_NOT_APPROVE_TITLE') : ''
      }
      onClickApprove={() => setShowActionModal(PROJECTION.STATUS.APPROVED)}
      disableApprove={
        !userCanAction || isGlobalBudgetApproved || !isGlobalBudgetPublished || notForecastClosed
      }
      buttonApproveName={isGlobalBudgetApproved ? t('STATE_TAG_APPROVED') : t('ACTION_APPROVE')}
      onClickApply={() => setShowDrawerRules(true)}
      disabledApply={!userCanViewAndABMRules || !isGlobalBudgetPublished}
      extraButtons={{
        module_name: 'forecast_global',
        submodule_name: globalForecastId,
      }}
      currencyOptions={currencyList.data}
      onChangeCurrency={(val) => setCurrencyChange(val)}
      defaultCurrency={currencyChange ? currencyChange : defaultCurrencyData?.id}
    />
  )

  const renderReportsTables = () => (
    <TabsReports
      tableId={tableId}
      setTableId={setTableId}
      loading={isScreenLoading}
      stepsForecastInstance={getStepsOfInstances(globalForecastDetail, options)}
      dataPeriod={dataPeriod}
      globalForecastName={globalForecastDetail?.name}
      selectedDates={selectedDates}
      filters={filters}
      tableLoading={isTableLoading}
      allDimensionsList={allDimensionsList}
      defaultPhase={defaultPhase}
      setDefaultPhase={setDefaultPhase}
      auxCurrencyId={auxCurrencyId}
      setDataComments={setDataComments}
      dataComments={dataComments}
      onRefetchComments={() => fetchCommentsList({ module: key })}
      loadingComments={isLoadingComments}
    />
  )

  const onRefetch = () => {
    setIsTableLoading(true)
    Promise.all([
      fetchDataEvolutionByOverallForecast(tableId, parseInt(globalForecastId), defaultPhase, {
        ...(auxCurrencyId && { currency_id: auxCurrencyId }),
      }),
      fetchDataComparativeByOverallForecast(tableId, parseInt(globalForecastId), {
        ...(auxCurrencyId && { currency_id: auxCurrencyId }),
      }),
    ]).then(() => setIsTableLoading(false))
  }

  return (
    <SiderLayout className="container-global-budget-detail">
      {renderHeader()}
      <Spin spinning={isScreenLoading} />
      {globalForecastDetail && !isScreenLoading && (
        <>
          {!showScreenSuccess && (
            <>
              <Row gutter={[8, 24]}>
                <Col span={24}>{renderUserActions()}</Col>
                <Col span={24}>{renderSelectors()}</Col>
                <Col span={24}>
                  <KPIsGlobalDetail
                    periodId={periodId}
                    globalBudgetId={globalForecastId}
                    budget_type="forecast"
                    setDataComments={setDataComments}
                    dataComments={dataComments}
                    onRefetchComments={() => fetchCommentsList({ module: key })}
                    loadingComments={isLoadingComments}
                  />
                </Col>
                <Col span={24}>{analysisList?.count > 0 && renderReportsTables()}</Col>
              </Row>
              <ActionModal
                globalBudgetId={globalForecastId}
                isActionApprove={isActionApprove}
                visible={showActionModal}
                onAction={isActionApprove ? approveGlobalForecast : publishGlobalForecast}
                onFetchDetail={() => fetchGlobalForecastDetail(globalForecastId)}
                onConfirm={() => setShowScreenSuccess(showActionModal)}
                onClose={() => setShowActionModal(null)}
              />
              <ToolsDrawer
                onRefetchDataTable={onRefetch}
                visible={showDrawerRules}
                onClose={() => setShowDrawerRules(false)}
                periodId={periodId}
                hasRule={true}
                globalProjectionId={globalForecastId}
                type="forecast_overall_budget"
                applyRule={(ruleId) =>
                  applyRuleForecast(globalForecastId, ruleId, { period: periodId })
                }
                unApplyRule={(ruleId) =>
                  unApplyRuleForecast(globalForecastId, ruleId, { period: periodId })
                }
                typeModule={GENERAL.MODULES_KEYS.MODULE_FORECAST_OVERALL}
                moduleId={globalForecastId}
                hasCurrency={true}
                isCurrencyLoading={isCurrencyLoading}
                currencyList={currencyList}
                params={{
                  overall_budget_id: globalForecastId,
                  module: 'OVERALL_FORECAST',
                }}
                disabledDate={{
                  cutDate:
                    forecasts?.values.length > 0
                      ? forecasts?.values.find((el) => el.id === parseInt(forecastId))?.year_month
                      : '',
                  startDatePeriod: moment(dataPeriod?.start_date).utc().format('YYYY-MM-DD'),
                }}
              />
            </>
          )}
          {showScreenSuccess && (
            <ScreenWellDone
              image={imageDefault}
              title={t('FEEDBACK_WELLDONE')}
              description={t('PLANNING_SCREEN_SUCCESS_FEEDBACK', {
                period: dataPeriod?.name,
                action: isActionApprove
                  ? t('ACTION_APPROVE').toLowerCase()
                  : t('ACTION_PUBLISH').toLowerCase(),
              })}
              footer={
                <Space>
                  <Button.Primary
                    onClick={() => setShowScreenSuccess(null)}
                    title="ACTION_FINISH"
                  />
                  {!isGlobalBudgetApproved && (
                    <Tooltip title={t('PLANNING_GLOBAL_BUDGET_INFORMATIVE_TOOLTIP_TITLE')}>
                      <Button.Default
                        onClick={() =>
                          onSetDefaultForecast({
                            setDefaultForecast: () => setDefaultForecast(globalForecastId),
                            setShowScreenSuccess,
                          })
                        }
                        title="ACTION_FIX"
                      />
                    </Tooltip>
                  )}
                </Space>
              }
            />
          )}
        </>
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  globalForecastDetail: forecast.selectors.getGlobalForecastDetail(state),
  analysisList: reports.selectors.getAnalysisList(state),
  periodList: planning.selectors.getPeriodList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  currencyList: configuration.selectors.getCurrencyList(state),
  commentsList: configuration.selectors.getCommentsList(state),
  forecasts: forecast.selectors.getForecasts(state),
})

const mapDispatchToProps = {
  fetchGlobalForecastDetail: forecast.actions.fetchGlobalForecastDetail,
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
  fetchAnalysisList: reports.actions.fetchAnalysisList,
  // fetchGlobalForecastDetailKPI: planning.actions.fetchGlobalForecastDetailKPI,
  approveGlobalForecast: forecast.actions.approveGlobalForecast,
  setDefaultForecast: forecast.actions.setDefaultForecast,
  publishGlobalForecast: forecast.actions.publishGlobalForecast,
  changeProjectionForecast: forecast.actions.changeProjectionForecast,
  fetchGlobalForecastEvolutionTable: forecast.actions.fetchGlobalForecastEvolutionTable,
  fetchDataEvolutionByOverallForecast: forecast.actions.fetchDataEvolutionByOverallForecast,
  fetchGlobalForecastComparisonTable: forecast.actions.fetchGlobalForecastComparisonTable,
  fetchDataComparativeByOverallForecast: forecast.actions.fetchDataComparativeByOverallForecast,
  unApplyRuleForecast: forecast.actions.unApplyRuleForecast,
  applyRuleForecast: forecast.actions.applyRuleForecast,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
  fetchCommentsList: configuration.actions.fetchCommentsList,
  fetchForecasts: forecast.actions.fetchForecasts,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalForecastDetail)
