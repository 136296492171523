import React from 'react'
import { Popconfirm, Space } from 'antd'
import { Button, ButtonActionBar, CustomBottomAction, DropdownMenu } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { SolutionOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'

const isStepClosing = (step) => step === 'closing'

const UserActionsPhases = ({
  onConfirmPopConfirm,
  onCancelPopconfirm,
  showPopConfirmSend,
  onClickSend,
  buttonName,
  disabled,
  dataPhase,
  loading,
  is_closing,
  buttonUploadData,
  onClickActivity,
  onClickApply,
  loadingDelete,
  visible,
  onCancelDeleteAll,
  onConfirmDeleteAll,
  onClickDeleteAll,
  onClickCreate,
  hasCreate = false,
  extraButtons,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space>
        {hasCreate && (
          <Button.PrimaryWhitRightIcon
            icon={<PlusCircleOutlined />}
            title="ACTION_ADD_NEW"
            onClick={onClickCreate}
            disabled={disabled}
          />
        )}
        <Popconfirm
          placement="bottomRight"
          title={
            <div style={{ width: 300 }}>
              {t('PLANNING_PHASES_ACTION_CONFIRM_POPCONFIRM_TITLE', {
                data: isStepClosing(dataPhase.key)
                  ? t('PLANNING_PHASES_CLOSE_CONFIRM_POPCONFIRM_TITLE')
                  : t('PLANNING_PHASES_SEND_CONFIRM_POPCONFIRM_TITLE'),
                action: isStepClosing(dataPhase.key)
                  ? t('ACTION_CLOSE').toLowerCase()
                  : t('ACTION_SEND').toLowerCase(),
              })}
            </div>
          }
          okText={isStepClosing(dataPhase.key) ? t('ACTION_CLOSE') : t('ACTION_SEND')}
          onConfirm={onConfirmPopConfirm}
          okButtonProps={{ loading: loading }}
          cancelText={t('ACTION_CANCEL')}
          onCancel={onCancelPopconfirm}
          visible={showPopConfirmSend}
        >
          <Button.Primary
            disabled={disabled}
            onClick={onClickSend}
            loading={loading}
            title={buttonName}
          />
        </Popconfirm>
        {!is_closing && buttonUploadData}
        <Button.Default onClick={onClickApply} disabled={disabled} title="LABEL_TOOLS" />
        <CustomBottomAction extraButtons={extraButtons} />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_ACTIVITIES'),
              icon: <SolutionOutlined />,
              onClick: onClickActivity,
            },
            {
              title: t('ACTION_DELETE_ALL'),
              icon: <DeleteOutlined />,
              onClick: onClickDeleteAll,
            },
          ]}
        />
        <Popconfirm
          title={t('POPCONFIRM_DELETE_ALL_PAGES')}
          placement="right"
          okText={t('ACTION_DELETE')}
          okButtonProps={{ loading: loadingDelete }}
          cancelButtonProps={{ disabled: loadingDelete }}
          onConfirm={onConfirmDeleteAll}
          cancelText={t('ACTION_CANCEL')}
          onCancel={onCancelDeleteAll}
          visible={visible}
        />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActionsPhases
