import {
  FilterFilled,
  FilterOutlined,
  InsertRowBelowOutlined,
  InsertRowRightOutlined,
  LayoutOutlined,
} from '@ant-design/icons'
import { Badge, Tooltip } from 'antd'
import update from 'immutability-helper'
import PropTypes from 'prop-types'
import React from 'react'
import PivotTableUI, { DraggableAttribute, Dropdown } from 'react-pivottable/PivotTableUI'
import Sortable from 'react-sortablejs'
import PivotTable from './PivotTable'
import { getSort, sortAs } from './Utilities'

class DraggableAttributeUX extends DraggableAttribute {
  constructor(props) {
    super(props)
    this.state = { open: false, filterText: '' }
  }

  render() {
    const hasFilter = Object.keys(this.props.valueFilter).length !== 0
    const filtered = hasFilter ? 'pvtFilteredAttribute' : ''
    return (
      <li data-id={this.props.name}>
        <span className={'pvtAttr ' + filtered}>
          {this.props.name}
          <span
            className="pvtTriangle"
            style={{ paddingLeft: 5, paddingRight: 5, marginLeft: 5, cursor: 'pointer' }}
            onClick={this.toggleFilterBox.bind(this)}
          >
            {hasFilter ? (
              <Badge dot>
                <FilterFilled style={{ color: 'rgba(0, 0, 0, 0.65)' }} />
              </Badge>
            ) : (
              <FilterOutlined style={{ color: 'rgba(0, 0, 0, 0.65)' }} />
            )}
          </span>
        </span>

        {this.state.open ? this.getFilterBox() : null}
      </li>
    )
  }
}

class PlaneTableUX extends PivotTableUI {
  setValuesInFilter(attribute, values) {
    const command = {
      valueFilter: {
        [attribute]: {
          $set: values.reduce((r, v) => {
            r[v] = true
            return r
          }, {}),
        },
      },
    }

    if (attribute in this.props.tableFilter) {
      command.tableFilter = { [attribute]: { $unset: values } }
    } else {
      const newValues = Object.keys(this.state.attrValues[attribute]).filter(
        (y) => !values.includes(y),
      )
      command.tableFilter = {
        [attribute]: {
          $set: newValues.reduce((r, v) => {
            r[v] = true
            return r
          }, {}),
        },
      }
    }

    this.sendPropUpdate(command)
  }

  addValuesToFilter(attribute, values) {
    const command = {}
    if (attribute in this.props.valueFilter) {
      command.valueFilter = {
        [attribute]: values.reduce((r, v) => {
          r[v] = { $set: true }
          return r
        }, {}),
      }
    } else {
      command.valueFilter = {
        [attribute]: {
          $set: values.reduce((r, v) => {
            r[v] = true
            return r
          }, {}),
        },
      }
    }

    if (attribute in this.props.tableFilter) {
      command.tableFilter = { [attribute]: { $unset: values } }
    }
    this.sendPropUpdate(command)
  }

  removeValuesFromFilter(attribute, values) {
    const command = { valueFilter: { [attribute]: { $unset: values } } }

    if (attribute in this.props.tableFilter) {
      command.tableFilter = {
        [attribute]: values.reduce((r, v) => {
          r[v] = { $set: true }
          return r
        }, {}),
      }
    } else {
      command.tableFilter = {
        [attribute]: {
          $set: values.reduce((r, v) => {
            r[v] = true
            return r
          }, {}),
        },
      }
    }

    this.sendPropUpdate(command)
  }

  makeDnDCell(items, onChange, classes) {
    return (
      <Sortable
        options={{
          group: 'shared',
          ghostClass: 'pvtPlaceholder',
          filter: '.pvtFilterBox',
          preventOnFilter: false,
        }}
        tag="td"
        className={classes}
        onChange={onChange}
      >
        {items.map((x) => (
          <DraggableAttributeUX
            name={x}
            key={x}
            attrValues={this.state.attrValues[x]}
            valueFilter={this.props.valueFilter[x] || {}}
            sorter={getSort(this.props.sorters, x)}
            menuLimit={this.props.menuLimit}
            setValuesInFilter={this.setValuesInFilter.bind(this)}
            addValuesToFilter={this.addValuesToFilter.bind(this)}
            moveFilterBoxToTop={this.moveFilterBoxToTop.bind(this)}
            removeValuesFromFilter={this.removeValuesFromFilter.bind(this)}
            zIndex={this.state.zIndices[x] || this.state.maxZIndex}
          />
        ))}
      </Sortable>
    )
  }

  rendererCell() {
    const rendererName =
      this.props.rendererName in this.props.renderers
        ? this.props.rendererName
        : Object.keys(this.props.renderers)[0]

    return (
      <td className="pvtAxisContainer pvtRenderers width-min-250">
        <Dropdown
          current={rendererName}
          values={Object.keys(this.props.renderers)}
          open={this.isOpen('renderer')}
          zIndex={this.isOpen('renderer') ? this.state.maxZIndex + 1 : 1}
          toggle={() =>
            this.setState({
              openDropdown: this.isOpen('renderer') ? false : 'renderer',
            })
          }
          setValue={this.propUpdater('rendererName')}
        />
      </td>
    )
  }

  aggregatorCell() {
    const numValsAllowed = this.props.aggregators[this.props.aggregatorName]([])().numInputs || 0

    const aggregatorCellOutlet = this.props.aggregators[this.props.aggregatorName]([])().outlet

    const sortIcons = {
      key_a_to_z: {
        rowSymbol: '↕',
        colSymbol: '↔',
        next: 'value_a_to_z',
      },
      value_a_to_z: {
        rowSymbol: '↓',
        colSymbol: '→',
        next: 'value_z_to_a',
      },
      value_z_to_a: {
        rowSymbol: '↑',
        colSymbol: '←',
        next: 'key_a_to_z',
      },
    }

    return (
      <td className="pvtVals width-min-250" rowSpan={2}>
        <Dropdown
          current={this.props.aggregatorName}
          values={Object.keys(this.props.aggregators)}
          open={this.isOpen('aggregators')}
          zIndex={this.isOpen('aggregators') ? this.state.maxZIndex + 1 : 1}
          toggle={() =>
            this.setState({
              openDropdown: this.isOpen('aggregators') ? false : 'aggregators',
            })
          }
          setValue={this.propUpdater('aggregatorName')}
        />
        <a
          role="button"
          className="pvtRowOrder"
          onClick={() => this.propUpdater('rowOrder')(sortIcons[this.props.rowOrder].next)}
        >
          {sortIcons[this.props.rowOrder].rowSymbol}
        </a>
        <a
          role="button"
          className="pvtColOrder"
          onClick={() => this.propUpdater('colOrder')(sortIcons[this.props.colOrder].next)}
        >
          {sortIcons[this.props.colOrder].colSymbol}
        </a>
        {numValsAllowed > 0 && <br />}
        {new Array(numValsAllowed).fill().map((n, i) => [
          <Dropdown
            key={i}
            current={this.props.vals[i]}
            values={Object.keys(this.state.attrValues).filter(
              (e) =>
                !this.props.hiddenAttributes.includes(e) &&
                !this.props.hiddenFromAggregators.includes(e),
            )}
            open={this.isOpen(`val${i}`)}
            zIndex={this.isOpen(`val${i}`) ? this.state.maxZIndex + 1 : 1}
            toggle={() =>
              this.setState({
                openDropdown: this.isOpen(`val${i}`) ? false : `val${i}`,
              })
            }
            setValue={(value) =>
              this.sendPropUpdate({
                vals: { $splice: [[i, 1, value]] },
              })
            }
          />,
          i + 1 !== numValsAllowed ? <br key={`br${i}`} /> : null,
        ])}
        {aggregatorCellOutlet && aggregatorCellOutlet(this.props.data)}
      </td>
    )
  }

  render() {
    const unusedAttrs = Object.keys(this.state.attrValues)
      .filter(
        (e) =>
          !this.props.rows.includes(e) &&
          !this.props.cols.includes(e) &&
          !this.props.hiddenAttributes.includes(e) &&
          !this.props.hiddenFromDragDrop.includes(e),
      )
      .sort(sortAs(this.state.unusedOrder))

    const unusedAttrsCell = this.makeDnDCell(
      unusedAttrs,
      (order) => this.setState({ unusedOrder: order }),
      `pvtAxisContainer border-left-none pvtUnused pvtHorizList`,
    )

    const colAttrs = this.props.cols.filter(
      (e) => !this.props.hiddenAttributes.includes(e) && !this.props.hiddenFromDragDrop.includes(e),
    )

    const colAttrsCell = this.makeDnDCell(
      colAttrs,
      this.propUpdater('cols'),
      'pvtAxisContainer border-left-none pvtHorizList pvtCols',
    )

    const rowAttrs = this.props.rows.filter(
      (e) => !this.props.hiddenAttributes.includes(e) && !this.props.hiddenFromDragDrop.includes(e),
    )
    const rowAttrsCell = this.makeDnDCell(
      rowAttrs,
      this.propUpdater('rows'),
      'pvtAxisContainer border-left-none pvtHorizList pvtRows',
      2,
    )
    const outputCell = (
      <PivotTable
        {...update(this.props, {
          data: { $set: this.state.materializedInput },
        })}
      />
    )

    return (
      <>
        <table className="pvtUi">
          <tbody onClick={() => this.setState({ openDropdown: false })}>
            <tr key="R">
              {this.rendererCell()}
              <td className="pvtAxisContainer border-right-none pvtUnused py-1 pr-0 width-25">
                <Tooltip placement="topLeft" title={'Dimensiones'}>
                  <LayoutOutlined style={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.65)' }} />
                </Tooltip>
              </td>
              {unusedAttrsCell}
            </tr>
            <tr>
              {this.aggregatorCell()}
              <td className="pvtAxisContainer border-right-none pvtUnused py-1 pr-0 width-25">
                <Tooltip placement="topLeft" title={'Filas'}>
                  <InsertRowBelowOutlined
                    style={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.65)' }}
                  />
                </Tooltip>
              </td>
              {rowAttrsCell}
            </tr>
            <tr key="A">
              <td className="pvtAxisContainer border-right-none pvtUnused py-1 pr-0 width-25">
                <Tooltip placement="topLeft" title={'Columas'}>
                  <InsertRowRightOutlined
                    style={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.65)' }}
                  />
                </Tooltip>
              </td>
              {colAttrsCell}
            </tr>
          </tbody>
        </table>
        <div className="pvt-table-container">{outputCell}</div>
      </>
    )
  }
}

PlaneTableUX.propTypes = Object.assign({}, PivotTable.propTypes, {
  onChange: PropTypes.func.isRequired,
  hiddenAttributes: PropTypes.arrayOf(PropTypes.string),
  hiddenFromAggregators: PropTypes.arrayOf(PropTypes.string),
  hiddenFromDragDrop: PropTypes.arrayOf(PropTypes.string),
  unusedOrientationCutoff: PropTypes.number,
  menuLimit: PropTypes.number,
})

PlaneTableUX.defaultProps = Object.assign({}, PivotTable.defaultProps, {
  hiddenAttributes: [],
  hiddenFromAggregators: [],
  hiddenFromDragDrop: [],
  unusedOrientationCutoff: 85,
  menuLimit: 5000,
})

export default PlaneTableUX
