import React, { useEffect, useState } from 'react'
import { Dropdown, Menu, Space, Spin } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import { aggregators2 } from '../../../../../components/PivotTable/Utilities'
import { GENERAL } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import PivotTableUX from '../../../../../components/PivotTable/PivotTableUX'
import TableRenderers from '../../../../../components/PivotTable/Renderers/Percentage/TableRenderers'
import _ from 'lodash'

const PercentageTable = (props) => {
  const {
    loading,
    settings,
    setSettings,
    dataPercentageTable,
    onCellEdit,
    hasComment,
    setHasComment,
    setViewComment,
  } = props
  const { dataSource, fields } = dataPercentageTable
  const [dataCell, setDataCell] = useState({})
  const { t } = useTranslation()
  let inputValue = null

  useEffect(() => {
    setSettings((prevState) => ({ ...prevState, data: dataSource }))
  }, [dataPercentageTable, dataSource, setSettings])

  return (
    <Spin spinning={loading}>
      <Dropdown
        trigger={['contextMenu']}
        overlay={
          <Menu>
            {GENERAL.OPTION_COMMENT.map((el) => (
              <Menu.Item
                key={el.key}
                icon={<CommentOutlined />}
                onClick={() => {
                  setViewComment(true)
                  setHasComment({ type: 'table', data: !hasComment.table })
                }}
              >
                {t(el.label)}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <div style={{ width: '100%' }}>
          <PivotTableUX
            renderers={Object.assign({}, TableRenderers)}
            aggregators={aggregators2}
            data={dataSource}
            onChange={(s) => setSettings(s)}
            vals={['amount']}
            cols={fields.columns}
            rows={fields.rows_default}
            aggregatorName="Average"
            hiddenFromDragDrop={fields.values}
            hiddenFromAggregators={fields.rows}
            tableFilter={{}}
            tableOptions={{
              clickCallback: (info, value, record, pivotData) => {
                if (_.isEmpty(dataCell) && info.target.className !== 'pvtTotal') {
                  setDataCell(record)
                  const originElement = info.target.innerHTML
                  const newElement = info.target
                  newElement.innerHTML = `<input type="number" step="0.00" value=${value} >`
                  const inputElement = document.querySelector('input[type="number"]')
                  inputElement.focus()
                  inputElement.addEventListener('input', function (e) {
                    inputValue = e.target.value
                  })
                  inputElement.addEventListener('blur', function () {
                    newElement.innerHTML = originElement
                    inputElement.removeEventListener('keypress', {})
                    inputElement.removeEventListener('input', {})
                    setDataCell({})
                    inputValue = null
                  })
                  inputElement.addEventListener('keypress', function (e) {
                    if (e.key === 'Enter') {
                      onCellEdit(parseFloat(inputValue), record, pivotData.props.vals[0], 'base')
                      inputElement.removeEventListener('keypress', {})
                      inputElement.removeEventListener('input', {})
                      setDataCell({})
                    }
                  })
                }
              },
            }}
            {...settings}
          />
        </div>
      </Dropdown>
    </Spin>
  )
}

export default PercentageTable
