import React, { useEffect, useRef, useState } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Dropdown, Menu, Spin } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import { GENERAL } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import PivotTableUX from '../../../../../components/PivotTable/PivotTableUX'
import PlaneTableUX from 'components/PivotTable/PlaneTableUX'
import TableRenderers from 'components/PivotTable/Renderers/Base/Plane'
import _ from 'lodash'

import '../../../../../components/PivotTable/pivottable.css'
import './NestedTable.scss'

const NestedTable = ({
  dataNestedTable,
  tableLoading,
  onCellEdit,
  canDelete,
  settings,
  setSettings,
  hasComment,
  setHasComment,
  setViewComment,
}) => {
  const [dataCell, setDataCell] = useState({})
  const enabledPlaneTable = useFeatureIsOn('feature-plane-table')
  const { t } = useTranslation()
  const inputValueRef = useRef(null)

  useEffect(() => {
    setSettings((prevState) => ({ ...prevState, data: dataNestedTable.data }))
  }, [dataNestedTable])

  if (enabledPlaneTable) {
    return (
      <Spin spinning={tableLoading}>
        <Dropdown
          trigger={['contextMenu']}
          overlay={
            <Menu>
              {GENERAL.OPTION_COMMENT.map((el) => (
                <Menu.Item
                  key={el.key}
                  icon={<CommentOutlined />}
                  onClick={() => {
                    setViewComment(true)
                    setHasComment({ type: 'table', data: !hasComment.table })
                  }}
                >
                  {t(el.label)}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <div style={{ width: '100%' }}>
            <PlaneTableUX
              renderers={Object.assign({}, TableRenderers)}
              data={dataNestedTable.data}
              onChange={(s) => setSettings(s)}
              vals={settings.default_value ? [settings.default_value] : ['amount']}
              cols={dataNestedTable.fields.columns}
              rows={dataNestedTable.fields.rows_default}
              aggregatorName={settings.default_format || 'Decimales'}
              hiddenFromDragDrop={['amount', 'price', 'quantity']}
              hiddenFromAggregators={
                dataNestedTable?.data[0] &&
                dataNestedTable?.data[0].filter(
                  (el) => el !== 'amount' && el !== 'price' && el !== 'quantity',
                )
              }
              tableFilter={{}}
              plotlyConfig={{
                responsive: true,
              }}
              tableOptions={{
                realMonths: dataNestedTable.fields?.real_months,
                clickCallback: (info, value, record, pivotData, vals) => {
                  if (canDelete) {
                    if (_.isEmpty(dataCell) && info.target.className !== 'pvtTotal') {
                      setDataCell(record)

                      const originElement = info.target.innerHTML
                      const newElement = info.target

                      newElement.innerHTML = `<input type="text" value="${value ?? 0}" />`
                      const inputElement = document.querySelector('input[type="text"]')

                      inputElement.focus()

                      const handleInput = (e) => {
                        inputValueRef.current = e.target.value
                      }

                      const handleBlur = () => {
                        newElement.innerHTML = originElement
                        inputElement.removeEventListener('input', handleInput)
                        inputElement.removeEventListener('blur', handleBlur)
                        inputElement.removeEventListener('keypress', handleKeyPress)
                        setDataCell({})
                        inputValueRef.current = null
                      }

                      const handleKeyPress = (e) => {
                        if (e.key === 'Enter') {
                          onCellEdit(
                            parseFloat(inputValueRef.current),
                            record,
                            pivotData.props.vals[0],
                            'base',
                          )
                          inputElement.removeEventListener('input', handleInput)
                          inputElement.removeEventListener('blur', handleBlur)
                          inputElement.removeEventListener('keypress', handleKeyPress)
                          setDataCell({})
                        }
                      }

                      inputElement.addEventListener('input', handleInput)
                      inputElement.addEventListener('blur', handleBlur)
                      inputElement.addEventListener('keypress', handleKeyPress)
                    }
                  }
                },
              }}
              {...settings}
            />
          </div>
        </Dropdown>
      </Spin>
    )
  }

  return (
    <Spin spinning={tableLoading}>
      <Dropdown
        trigger={['contextMenu']}
        overlay={
          <Menu>
            {GENERAL.OPTION_COMMENT.map((el) => (
              <Menu.Item
                key={el.key}
                icon={<CommentOutlined />}
                onClick={() => {
                  setViewComment(true)
                  setHasComment({ type: 'table', data: !hasComment.table })
                }}
              >
                {t(el.label)}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <div style={{ width: '100%' }}>
          <PivotTableUX
            data={dataNestedTable.data}
            onChange={(s) => setSettings(s)}
            vals={settings.default_value ? [settings.default_value] : ['amount']}
            cols={dataNestedTable.fields.columns}
            rows={dataNestedTable.fields.rows_default}
            aggregatorName={settings.default_format || 'Decimales'}
            hiddenFromDragDrop={['amount', 'price', 'quantity']}
            hiddenFromAggregators={
              dataNestedTable?.data[0] &&
              dataNestedTable?.data[0].filter(
                (el) => el !== 'amount' && el !== 'price' && el !== 'quantity',
              )
            }
            tableFilter={{}}
            plotlyConfig={{
              responsive: true,
            }}
            tableOptions={{
              realMonths: dataNestedTable.fields?.real_months,
              clickCallback: (info, value, record, pivotData, vals) => {
                if (canDelete) {
                  if (_.isEmpty(dataCell) && info.target.className !== 'pvtTotal') {
                    setDataCell(record)

                    const originElement = info.target.innerHTML
                    const newElement = info.target

                    newElement.innerHTML = `<input type="text" value="${value ?? 0}" />`
                    const inputElement = document.querySelector('input[type="text"]')

                    inputElement.focus()

                    const handleInput = (e) => {
                      inputValueRef.current = e.target.value
                    }

                    const handleBlur = () => {
                      newElement.innerHTML = originElement
                      inputElement.removeEventListener('input', handleInput)
                      inputElement.removeEventListener('blur', handleBlur)
                      inputElement.removeEventListener('keypress', handleKeyPress)
                      setDataCell({})
                      inputValueRef.current = null
                    }

                    const handleKeyPress = (e) => {
                      if (e.key === 'Enter') {
                        onCellEdit(
                          parseFloat(inputValueRef.current),
                          record,
                          pivotData.props.vals[0],
                          'base',
                        )
                        inputElement.removeEventListener('input', handleInput)
                        inputElement.removeEventListener('blur', handleBlur)
                        inputElement.removeEventListener('keypress', handleKeyPress)
                        setDataCell({})
                      }
                    }

                    inputElement.addEventListener('input', handleInput)
                    inputElement.addEventListener('blur', handleBlur)
                    inputElement.addEventListener('keypress', handleKeyPress)
                  }
                }
              },
            }}
            {...settings}
          />
        </div>
      </Dropdown>
    </Spin>
  )
}

export default NestedTable
