import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Select, Space, Tooltip } from 'antd'
import { Button, ButtonActionBar, CustomBottomAction, Typography } from 'modules/core/components'
import { generateSelectOptions } from 'modules/core/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'

const UserActionsGlobalDetail = ({
  onClickPublish,
  disabledPublish,
  buttonPublishName,
  tooltipApprove,
  onClickApprove,
  disableApprove,
  buttonApproveName,
  onClickApply,
  disabledApply,
  extraButtons,
  defaultCurrency,
  currencyOptions,
  onChangeCurrency,
}) => {
  const enabled = useFeatureIsOn('feature-moneda-seguimiento')
  const { t } = useTranslation()
  return (
    <ButtonActionBar position="space-between">
      <Space direction="horizontal">
        {enabled && (
          <>
            <Typography.Body>{t('LABEL_CURRENCY')}:</Typography.Body>
            <Select
              bordered={false}
              value={defaultCurrency}
              placeholder={t('ACTION_SELECT')}
              options={generateSelectOptions({ options: currencyOptions })}
              onChange={onChangeCurrency}
            />
          </>
        )}
      </Space>
      <Space direction="horizontal">
        <Button.PrimaryGhost
          onClick={onClickPublish}
          disabled={disabledPublish}
          title={buttonPublishName}
        />
        <Tooltip title={tooltipApprove}>
          <Button.Primary
            onClick={onClickApprove}
            disabled={disableApprove}
            title={buttonApproveName}
          />
        </Tooltip>
        <Button.Default onClick={onClickApply} disabled={disabledApply} title="LABEL_TOOLS" />
        <CustomBottomAction extraButtons={extraButtons} />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActionsGlobalDetail
