import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ProjectionTrackingScreen } from 'modules/core/components'
import { AdjustmentDetail } from 'modules/adjustments/containers'
import ControlList from './ControlList/ControlList'
import ControlDetail from './ControlDetail/ControlDetail'

const Home = () => (
  <Switch>
    <Route exact path="/seguimiento/:periodId/" component={ControlList} />
    <Route
      exact
      path="/seguimiento/:periodId/ajustes/:adjustmentId/"
      component={AdjustmentDetail}
    />
    <Route exact path="/seguimiento/:periodId/:conceptName/:conceptId" component={ControlDetail} />
    <Route
      exact
      path="/seguimiento/:periodId/:conceptName/actividad/:projectionId"
      component={ProjectionTrackingScreen}
    />
  </Switch>
)

export default Home
