const report = {
  REPORT_TEMPLATE_FILE_NAME: 'Plantilla Plika - Reporte {{name}} - {{period}} ({{date}})',
  REPORT_CARD_TITLE_NO_DATA:
    'Cuando el presupuesto económico esté en revisión o aprobado, acá presentaremos un resumen de los indicadores más importantes de tu reporte.',
  REPORT_GRAPHIC_TITLE: 'Evolución y comparación mensual',

  REPORT_STRUCTURE_FILE_NAME: 'Plika - Configuración reporte (estructura)',
  REPORT_EVOLUTION_FILE_NAME_DEFAULT: 'Plika - Análisis reportes',
  REPORT_EVOLUTION_FILE_NAME:
    'Plika - Análisis reportes - {{periodName}} (evolución mensual) ({{date}})',
  REPORT_COMPARISON_FILE_NAME:
    'Plika - Análisis reportes - {{periodName}} (Comparaciones) ({{date}})',
  REPORT_PPT_FILE_NAME: 'Plika - Reporte {{reportName}} - {{periodName}} ({{date}})',
  REPORT_OTHER_CHECKBOX_TEXT: 'Convertir signo de linea de reporte seleccionada',
  REPORT_OTHER_CHECKBOX_TEXT_TOOTIP:
    'Al convertir el signo verá los valores negativos en positivos reflejados en su tabla.',

  REPORT_DIMENSION_SHOW_CANT_LABEL: 'Cantidad de dimensiones visibles',

  REPORT_VALUES_RATIO: 'Valores con ratio',

  REPORT_PLIKA_CARD_TITLE: 'Reportes Plika',
  REPORT_PLIKA_CARD_BODY_TITLE: 'Analiza tus métricas más relevantes',
  REPORT_PLIKA_CARD_BODY_DESCRIPTION:
    'Accede a tus reportes, para visualizar las métricas más relevantes de tus datos, gráficos de evolución y comparaciones.',

  //reporte self-service
  REPORT_SELF_SERVICE_FIELD: 'Reportes self-services Quicksight',
  REPORT_SELF_SERVICE_CARD_BODY_TITLE: 'Crea reportes con la información que desees',
  REPORT_SELF_SERVICE_ENTER_PERIOD_DESCRIPTION:
    'Visualiza y organiza la información que desees acompañada por gráficos para analizar de manera sencilla y rápida tus datos.',

  //financiero
  REPORT_FINANCIAL_NO_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'No hay datos cargados del período anterior para visualizar esta sección',

  REPORT_DETAIL_FAIL_FEEDBACK_DESCRIPTION:
    'Se debe enviar todos los parámetros requeridos para visualizar el reporte',
  REPORT_COMPARATIVE_ACCUMULATIVE: 'Acumulado',
  REPORT_COMPARATIVE_MONTHLY: 'Mensual',

  REPORT_DASHBOARD_CREATE_MODAL_TITLE: '{{action}} elementos',
  REPORT_DASHBOARD_EMPTY_AREA_DESCRIPTION:
    'Crea tus elementos y elige las opciones que más se ajusten a tus necesidades',
  REPORT_PLACEHOLDER_VERTICAL_ANALYSIS: 'Seleccione una linea para hacer análisis vertical',
}

export default report
