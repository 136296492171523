import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  CreateGlobalProjectionModal,
  EmptyScreen,
  ProjectionCardList,
  UserActionsGlobal,
} from 'modules/core/components'
import { Spin, Row, Col } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isApproved } from 'modules/core/utils'
import { PROJECTION, ROLES } from 'modules/core/constants'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import forecast from 'modules/forecast'
import planning from 'modules/planning'
import configuration from 'modules/configuration'

const { FORECAST__GLOBAL_FORECAST__VIEW, FORECAST__GLOBAL_FORECAST__ABM } = ROLES

const FORECAST_CONCEPT = {
  [PROJECTION.GLOBAL_FORECAST]: {
    VIEW: FORECAST__GLOBAL_FORECAST__VIEW,
    ABM: FORECAST__GLOBAL_FORECAST__ABM,
  },
}

const GlobalForecastList = ({
  globalForecastList,
  fetchGlobalForecastList,
  deleteGlobalForecast,
  editGlobalForecastName,
  setDefaultForecast,
  createGlobalForecast,
  fetchForecastInstanceList,
  forecastInstanceList,
  changeCardColor,
  duplicateGlobalForecast,
  fetchCommentsList,
  commentsList,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [dataComments, setDataComments] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  let { periodId, forecastId } = useParams()
  const { t } = useTranslation()
  let history = useHistory()

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `${auxKey}?tab=globalForecast` : auxKey

  useEffect(() => {
    setIsLoadingComments(true)
    fetchCommentsList({ module: key }).then(() => setIsLoadingComments(false))
  }, [fetchCommentsList, key])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    setIsLoading(true)
    fetchGlobalForecastList({ forecast_id: forecastId }).then(() => {
      setIsScreenLoading(false)
      setIsLoading(false)
    })
    return () => {
      setIsScreenLoading(false)
      setIsLoading(false)
    }
  }, [fetchGlobalForecastList, forecastId])

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PLANNING_GLOBAL_BUDGET_EMPTY_SCREEN_TITLE')}
      footer={
        <CreateGlobalProjectionModal
          periodId={periodId}
          title={t('FORECAST_CREATE_GLOBAL_FORECAST_MODAL_TITLE')}
          globalText="LABEL_GLOBAL_FORECAST"
          role={FORECAST__GLOBAL_FORECAST__ABM}
          onFetchGlobalBudgetList={() => fetchGlobalForecastList({ forecast_id: forecastId })}
          text={t('LABEL_GLOBAL_FORECAST').toLowerCase()}
          createGlobalProjection={createGlobalForecast}
          type="forecast"
          fetchProjectionInstanceList={(conceptId) =>
            fetchForecastInstanceList(forecastId, conceptId)
          }
          projectionInstanceList={forecastInstanceList}
        />
      }
    />
  )

  const renderTopActions = () => (
    <UserActionsGlobal
      globalBudgetApproved={globalForecastList?.values.filter((globalBudget) =>
        isApproved(globalBudget.status),
      )}
      periodId={periodId}
      title={t('FORECAST_CREATE_GLOBAL_FORECAST_MODAL_TITLE')}
      globalText="LABEL_GLOBAL_FORECAST"
      role={FORECAST__GLOBAL_FORECAST__ABM}
      onFetchGlobalBudgetList={() => fetchGlobalForecastList({ forecast_id: forecastId })}
      createGlobalProjection={createGlobalForecast}
      type="forecast"
      fetchProjectionInstanceList={(conceptId) => fetchForecastInstanceList(forecastId, conceptId)}
      projectionInstanceList={forecastInstanceList}
      setSearchValue={setSearchValue}
      module_name="forecast_global"
    />
  )

  const renderDataSource = () => {
    if (searchValue) {
      return globalForecastList?.values.filter((el) =>
        el.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
    } else {
      return globalForecastList?.values
    }
  }

  const renderGlobalForecastList = () => (
    <ProjectionCardList
      dataSource={renderDataSource()}
      type={PROJECTION.GLOBAL_FORECAST}
      role={FORECAST_CONCEPT}
      onOpen={(id) => history.push(`/forecast/${periodId}/${forecastId}/forecastglobal/${id}`)}
      reloadList={() => fetchGlobalForecastList({ forecast_id: forecastId })}
      deleteProjection={deleteGlobalForecast}
      editProjectionName={editGlobalForecastName}
      setDefaultProjection={setDefaultForecast}
      loading={isLoading}
      changeCardColor={changeCardColor}
      module="globalForecast"
      duplicateCard={duplicateGlobalForecast}
      setDataComments={setDataComments}
      dataComments={dataComments}
      onRefetchComments={() => fetchCommentsList({ module: key })}
      loadingComments={isLoadingComments}
    />
  )

  return (
    <Spin spinning={isScreenLoading} size="large">
      {!isScreenLoading && (
        <Row gutter={[24, 24]}>
          {globalForecastList?.count <= 0 && <Col span={24}>{renderNoDataScreen()}</Col>}
          {globalForecastList?.count > 0 && (
            <>
              <Col span={24}>{renderTopActions()}</Col>
              <Col span={24}>{renderGlobalForecastList()}</Col>
            </>
          )}
        </Row>
      )}
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  globalForecastList: forecast.selectors.getGlobalForecastList(state),
  forecastInstanceList: forecast.selectors.getForecastInstanceList(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  fetchGlobalForecastList: forecast.actions.fetchGlobalForecastList,
  deleteGlobalForecast: forecast.actions.deleteGlobalForecast,
  editGlobalForecastName: forecast.actions.editGlobalForecastName,
  setDefaultForecast: forecast.actions.setDefaultForecast,
  createGlobalForecast: forecast.actions.createGlobalForecast,
  fetchForecastInstanceList: forecast.actions.fetchForecastInstanceList,
  changeCardColor: planning.actions.changeCardColor,
  duplicateGlobalForecast: forecast.actions.duplicateGlobalForecast,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalForecastList)
