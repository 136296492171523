const planning = {
  //Files names
  PLANNING_TEMPLATE_NAME_DEFAULT: 'Plika Template- Planning',
  PLANNING_TEMPLATE_NAME: 'Plika Template - Planning {{name}} - {{periodName}} ({{date}})',
  PLANNING_GLOBAL_BUDGET_EVOLUTION_TABLE_FILE_NAME: `Plika - Pllaning {{name}}({{reportName}}) - {{periodName}} ({{date}}) (Global budget - monthly evolution)`,
  PLANNING_GLOBAL_BUDGET_COMPARISON_TABLE_FILE_NAME: `Plika - Planning {{name}}({{reportName}}) - {{periodName}} ({{date}}) (Global budget - comparisons)`,
  PLANNING_EVOLTION_FILE_NAME: 'Plika - Planning (monthly evolution)',
  PLANNING_TEMPLATE_UPLOAD_VARIABLES_VALUES:
    'Plika Template - Upload variable values {{periodName}} ({{date}})',
  PLANNING_COMPARISON_FILE_NAME: 'Plika - Planning (Comparisons)',
  PLANNING_PHASE_TABLE_FILE_NAME:
    'Plika - Planning {{name}} phase {{phase}} ({{conceptName}}) - {{periodName}} ({{date}})',

  PLANNING_DISABLED_CREATION_TOOLTIP:
    'You cannot create new budgets because this period already has approved budgets',

  //Text modal create budget
  PLANNING_CREATE_FORM_BUDGET_TITLE: 'Create new budget',
  PLANNING_CREATE_BUDGET_SUCCESS_FEEDBACK: 'The budget was created successfully.',
  PLANNING_CREATE_BUDGET_ERROR_FEEDBACK: "We couldn't create the budget. Please try again.",
  PLANNING_CREATE_FORM_BUDGET_PLACEHOLDER: 'For example:{{name}} new',

  PLANNING_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL: 'The basis on which to create your budget',
  PLANNING_CREATE_NEW_BUDGET_BASE_DATES_FORM_LABEL: 'Range of months',
  PLANNING_CREATE_NEW_BUDGET_BASE_OPTION_FORM_SELECT: 'Zero base',
  PLANNING_CREATE_NEW_BUDGET_BASE_MODAL_TITLE: 'Load new base budget',
  PLANNING_CREATE_NEW_BUSGET_BASE_CHOOSE_TYPE_BASE_FORM_LABEL: 'Base type',
  PLANNING_CREATE_NEW_BUSGET_BASE_DATA_FILTER_FORM_LABEL: 'Data filter',
  PLANNING_CREATE_NEW_BUDGET_AVERAGE_OPTION_FORM_SELECT: 'Average historical base',
  PLANNING_CREATE_NEW_BUDGET_PREVIUS_PERIOD_OPTION_FORM_SELECT: 'Historical Base',
  PLANNING_CREATE_NEW_BUDGET_UPLOAD_FILE_OPTION_FORM_SELECT: 'File load base',
  PLANNING_CREATE_NEW_BUDGET_ARIMA_ALGORITHM_OPTION_FORM_SELECT: 'Arima algorithm base',
  PLANNING_CREATE_NEW_BUDGET_REGRESION_LINEAL_ALGORITHM_OPTION_FORM_SELECT:
    'The basis by algorithm: Linear Regression',
  PLANNING_CREATE_NEW_BUDGET_SARIMA_ALGORITHM_OPTION_FORM_SELECT: 'The basis by algorithm: Sarima',
  PLANNING_CREATE_NEW_BUDGET_PROPHET_ALGORITHM_OPTION_FORM_SELECT:
    'The basis by algorithm: Prophet',

  //Texts modal deadline
  PLANNING_CREATE_FORM_DEADLINE_DATE_PLACEHOLDER: 'Select day',
  PLANNING_CREATE_FORM_DEADLINE_TIME_PLACEHOLDER: 'Select time',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_LABEL: 'Create reminder',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE: 'None',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_IN_DEADLINE: 'In the deadline',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE_ONE_DAY_BEFORE: '1 day before',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE_TWO_DAY_NEFORE: ' 2 days before',
  PLANNING_CREATE_DEADLINE_SUCCESS_FEEDBACK: 'The upload deadline has been set.',
  PLANNING_CREATE_DEADLINE_ERROR_FEEDBACK:
    'We were unable to set the upload deadline. Please try again.',
  PLANNING_UPDATE_DEADLINE_SUCCESS_FEEDBACK:
    'The deadline for loading has been updated successfully',
  PLANNING_UPDATE_DEADLINE_ERROR_FEEDBACK:
    'We were unable to update the upload deadline. Please try again.',
  PLANNING_DEADLINE_MODAL_TITLE: '{{file}} upload deadline',

  //Cards empty
  PLANNING_EMPTY_CARD_BODY: 'Enter and start budgeting',

  //Notification budget
  PLANNING_COPY_BUDGET_ERROR_FEEDBACK: 'We were unable to create budget copy. Please try again.',
  PLANNING_DELETE_BUDGET_SUCCESS_FEEDBACK: 'Budget was successfully removed.',
  PLANNING_DELETE_BUDGET_ERROR_FEEDBACK: "We couldn't delete this budget. Please try again.",
  PLANNING_EDIT_NAME_BUDGET_SUCCESS_FEEDBACK: 'Budget name was successfully edited.',
  PLANNING_EDIT_NAME_BUDGET_ERROR_FEEDBACK: 'We were unable to edit budget name. Please try again.',
  PLANNING_NEXT_STEP_ERROR_FEEDBACK: 'Error when moving to the next stage',
  PLANNING_CLOSING_STEP_ERROR_FEEDBACK: 'Error when closing the budget',

  //Modal global budget create
  PLANNING_GLOBAL_BUDGET_CREATE_FORM_TITLE: 'Create global budget',
  PLANNING_GLOBAL_BUDGET_CREATE_FORM_INPUT_PLACEHOLDER: 'Eg: new global budget',

  //Drawer scenario
  PLANNING_VARS_EMPTY_SCREEN_UPLOAD_SUBTITLE: 'Start loading your variables',
  PLANNING_DRAWER_SCENARIO_NO_VARIABLES_VALUES: 'The variable still does not have assigned values',
  PLANNING_DRAWER_SCENARIO_NO_VARIABLES: 'There are no variables yet',

  PLANNING_RULES_APPLY_SUCCESS_FEEDBACK: 'The rule has been {{action}} correctly.',
  RULES_APPLY_TEXT: 'Applied',
  RULES_UNAPPLY_TEXT: 'Unapplied',
  PLANNING_RULES_APPLY_ERROR_FEEDBACK: 'We could not {{action}} the rule. Please, I tried again.',

  PLANNING_VARIABLE_EDIT_TOOLTIP: 'Click to edit variable',
  PLANNING_VARIABLE_CONFIG_TOOLTIP: 'To edit this field, please go to variables configuration',
  PLANNING_VARIABLE_CONFIG_LINK: 'Go to variables list',
  PLANNING_VARIABLE_CONFIG_LINK_ACTION: 'Edit from configuration',

  PLANNING_VARIABLES_RETURN_CONFIRM_TITLE: 'Return to variables` list ',
  PLANNING_VARIABLES_RETURN_CONFIRM_TEXT: 'Are you sure you want to return to variables` list?',
  PLANNING_VARIABLES_RETURN_CONFIRM_TOOLTIP:
    ' If you return without saving you will lose the values loaded so far',

  PLANNING_ACTION_MODAL_TITLE: '{{action}} budget',
  PLANNING_APPROVE_MODAL_TEXT:
    'You are about to finalize the budget process for your period. Are you sure you want to do it? Remember that once you approve the budget, you can no longer modify any data or upload more files.',
  PLANNING_SCREEN_SUCCESS_FEEDBACK: 'You have just {{action}} the {{period}} :)', //NOTE: COMENTADO  HASTA QUE SE PUEDA NOTIFICAR. Do you want to break the news to everyone who participated in this planning?',
  PLANNIG_PUBLISH_MODAL_TEXT:
    'You are about to publish your budget. In this instance you can edit, continue loading data, compare budgets with each other and visualize your data in analysis, forecast and actual section',

  //Empty screen
  PLANNING_EMPTY_SCREEN_TITLE: "You don't have any budget created yet!",
  PLANNING_EMPTY_SCREEN_BODY:
    'Create a budget, set priorities and build as many projections and scenarios as you need',
  PLANNING_BUDGET_CREATE_ACTION: 'Create budget',

  //Empty screen global budget
  PLANNING_GLOBAL_BUDGET_EMPTY_SCREEN_TITLE: 'You still have no global budget created!',

  PLANNING_GLOBAL_BUDGET_FIXED_POPOVER_TEXT:
    'You selected this budget as default. This means that you will visualize your data throughout the tool.',
  PLANNING_GLOBAL_BUDGET_NOT_DATA_BUDGET_TOOLTIP_TITLE:
    'To perform a general analysis you must have data in at least 2 budgets of different concepts.',
  PLANNING_GLOBAL_BUDGET_MODIFY_BUDGET_POPCONFIRM_TITLE:
    'If you change the settings for the global budget, all data will be updated. Are you sure you want to continue?',
  PLANNING_GLOBAL_BUDGET_INFORMATIVE_TOOLTIP_TITLE: 'Click to establish this budget as default.',
  PLANNING_GLOBAL_BUDGET_ADD_NEW_COMBINATION_ACTION: 'Add new combination',
  PLANNING_GLOBAL_BUDGET_DELETE_COMBINATION_ACTION: 'Eliminate combination',
  PLANNING_GLOBAL_BUDGET_CREATE_NEW_CHOOSE_TYPE_BUDGET_FORM_SELECT_LABEL:
    'Choose the budget types that will make up your {{projection}}',

  PLANNING_PHASES_INFORMATIVE_DISABLED_TOOLTIP_TITLE:
    'Once you send the information from the previous stage you can visualize this stage.',
  PLANNING_PHASES_ACTION_CONFIRM_POPCONFIRM_TITLE: `{{data}} Once you do this, you won't be able to edit your data, only view it. Are you sure you want to {{action}} now?`,
  PLANNING_PHASES_CLOSE_CONFIRM_POPCONFIRM_TITLE: 'You are to close this budget',
  PLANNING_PHASES_SEND_CONFIRM_POPCONFIRM_TITLE:
    'You are about to move your budget to the next stage',
  PLANNING_PHASES_SEND_TOPDOWN_ACTION: 'Send to Topdown',
  PLANNING_PHASES_SEND_BOTTOMUP_ACTION: 'Send to Bottomup',
  PLANNING_PHASES_SEND_CLOSING_ACTION: 'Send to closure',

  //reglas de negocio
  PLANNING_FORMULAS_DESCRIPTION_NOTIFICATION_SUCCESS: 'To display enter the factor details`.',
  PLANNING_FORMULA_SAVE_DATA_TOOLTIP_TITLE:
    'You must save the values ​​entered to enable this action.',
  PLANNING_FACTOR_POPCONFIRM_SAVE_CHANGES:
    'Are you sure you want to save the changes made? Keep in mind that this will impact the calculation and its results.',
  PLANNING_RULE_KEEP_DATA_INFORMATIVE_ALERT_DESCRIPTION:
    'To maintain the values ​​you modify, you must always apply the rule after saving them',

  PLANNING_DISPLAY_NEGATIVE_DATA_FORM_LABEL: 'Visualization negative values',

  //multimoneda
  PLANNING_CURRENCY_EMPTY_SCREEN_TITLE:
    'You do not have any activated origin currencies to show yet!',
  PLANNING_CURRENCY_CANCEL_POPCONFIRM_TITLE: 'Are you sure you want to cancel the changes?',
  PLANNING_CURRENCY_LOAD_ALL_EXCHANGE_RATE_ALERT_DESCRIPTION:
    'To save the exchange rate for every month they must have a loaded value.',
  PLANNING_CURRENCY_ALL_VALUES_TOOLTIP_TITLE: 'You must complete with values every month',

  PLANNING_PERCENTAGE_EDITION_CELL_INFORMATIVE_ALERT_MESSAGE:
    'When entering % the editing will be done in percentage',

  PLANNING_EMPTY_DATA_CARDS_BUDGET_TYPE_BODY_TEXT:
    'Enter to start loading {{title}} and to see a summary of your data',

  PLANNING_VALUES_FACTORS_APPLIED: 'Values ​​with rules applied',
}

export default planning
