import React, { useState } from 'react'
import moment from 'moment'
import { Card, DatePicker, Dropdown, Menu, message, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Typography } from '../../../../../../../core/components'
import { CardExtra, CardFooter, CardTitle } from './components'
import { handleEdit, onDeleteForecast } from './utils'
import { connect } from 'react-redux'
import { CommentOutlined } from '@ant-design/icons'
import forecast from 'modules/forecast'
import login from 'modules/login'
import configuration from 'modules/configuration'
import _ from 'lodash'
import { generateMessageError } from 'modules/core/utils'
import { GENERAL } from 'modules/core/constants'

const ForecastCard = ({
  forecast,
  userCanCreate,
  onRefetch,
  deleteForecast,
  editForecastName,
  hasComment,
  setHasComment,
  loggedUser,
  setDataComments,
  comments,
  onRefetchComments,
  loading,
  createComment,
  removeNewComment,
}) => {
  const [editName, setEditName] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [deleteForecastId, setDeleteForecastId] = useState(null)
  const [dataEdit, setDataEdit] = useState({})
  const [viewComment, setViewComment] = useState(false)
  const [isCommentSaving, setIsCommentSaving] = useState(false)

  let history = useHistory()
  let { periodId } = useParams()
  const { t } = useTranslation()

  const key = window.location.pathname.replaceAll('/', '-')

  const onCloseEdit = (value) => {
    setEditName(false)
    setIsSaving(false)
    setDataEdit({ ...dataEdit, name: value })
  }

  const onCloseDelete = () => {
    setDeleteForecastId(null)
    setIsSaving(false)
  }

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-card-${forecast.id}`,
      module: key,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsCommentSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsCommentSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsCommentSaving(false)
        setIsNewThread(false)
      })
  }

  return (
    <Dropdown
      trigger={['contextMenu']}
      overlay={
        <Menu>
          {GENERAL.OPTION_COMMENT.map((el) => (
            <Menu.Item
              key={el.key}
              icon={<CommentOutlined />}
              onClick={() => {
                setViewComment(true)
                setHasComment({
                  type: 'card',
                  data: !_.isEmpty(hasComment?.card)
                    ? !_.isEmpty(hasComment?.card[key])
                      ? !hasComment?.card[key][forecast.id]
                      : true
                    : true,
                  key,
                  id: forecast.id,
                })
              }}
            >
              {t(el.label)}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Card
        title={
          <CardTitle
            loading={isSaving}
            defaultValue={forecast.name}
            editName={editName}
            onBlur={(e) =>
              handleEdit({
                value: e.target.value,
                dataEdit,
                setIsLoading: setIsSaving,
                editForecastName,
                onRefetch,
                onClose: () => onCloseEdit(e.target.value),
              })
            }
            onPressEnter={(e) =>
              handleEdit({
                value: e.target.value,
                dataEdit,
                setIsLoading: setIsSaving,
                editForecastName,
                onRefetch,
                onClose: () => onCloseEdit(e.target.value),
              })
            }
            title={forecast.name}
          />
        }
        headStyle={{ height: 56 }}
        bodyStyle={{ height: 126 }}
        extra={
          <CardExtra
            loading={isSaving}
            onConfirmPopConfirm={() =>
              onDeleteForecast({
                setIsLoading: setIsSaving,
                deleteForecast: () => deleteForecast(deleteForecastId),
                onRefetch,
                onClose: onCloseDelete,
              })
            }
            onCancelPopConfirm={() => setDeleteForecastId(null)}
            visiblePopConfirm={deleteForecastId === forecast.id}
            status={forecast.status}
            tooltiTitle={t('FIELD_CREATOR_INFO', { username: forecast.user })}
            onClickEdit={() => setDeleteForecastId(forecast.id)}
            disabled={!userCanCreate}
            onClickDelete={() => {
              setEditName(true)
              setDataEdit({ id: forecast.id, name: forecast.name })
            }}
            showComments={
              viewComment ||
              comments.filter((el) => el.key === `${key}-card-${forecast.id}` && !el.resolved)
                .length > 0
            }
            hasComment={
              !_.isEmpty(hasComment?.card)
                ? !_.isEmpty(hasComment?.card[key]) && hasComment?.card[key][forecast.id]
                : false
            }
            setHasComment={(visible) =>
              setHasComment({ type: 'card', data: visible, key, id: forecast.id })
            }
            setViewComment={setViewComment}
            loggedUser={loggedUser}
            setDataComments={setDataComments}
            comments={comments.filter(
              (el) => (el.key === `${key}-card-${forecast.id}` && !el.resolved) || !el.text,
            )}
            onRefetchComments={onRefetchComments}
            loadingComments={loading || isCommentSaving}
            onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
              handleCreate({ text, usersIds, idComment, setIsNewThread })
            }
            onCleanHasComment={() => {
              setHasComment({ type: 'card', data: false, key, id: forecast.id })
              setViewComment(false)
            }}
            removeNewComment={removeNewComment}
          />
        }
        actions={[
          <CardFooter onClick={() => history.push(`/forecast/${periodId}/${forecast.id}`)} />,
        ]}
      >
        <Space direction="vertical">
          <Typography.Body level={2}>{t('FORECAST_CUT_DATE_FIELD')}</Typography.Body>
          <DatePicker
            style={{ width: 266 }}
            value={moment(forecast.year_month, 'YYYY-MM-DD')}
            disabled={true}
            format="MMMM YYYY"
          />
        </Space>
      </Card>
    </Dropdown>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  deleteForecast: forecast.actions.deleteForecast,
  editForecastName: forecast.actions.editForecastName,
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForecastCard)
