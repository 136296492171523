import React from 'react'
import { Dropdown, Menu, Spin } from 'antd'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { connect } from 'react-redux'
import { CommentOutlined } from '@ant-design/icons'
import control from 'modules/control'
import PivotTableUX from '../../../../../../../../components/PivotTable/PivotTableUX'

import PlaneTableUX from 'components/PivotTable/PlaneTableUX'
import TableRenderers from 'components/PivotTable/Renderers/Base/Plane'

import './ProjectionControlTable.scss'
import '../../../../../../../../components/PivotTable/pivottable.css'
import { GENERAL } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'

const ProjectionControlTable = ({
  dataSource,
  fields,
  isTableLoading,
  settings,
  setSettings,
  hasComment,
  setViewComment,
  setHasComment,
}) => {
  // const [settings, setSettings] = useState({})
  const enabledPlaneTable = useFeatureIsOn('feature-plane-table')
  const { t } = useTranslation()

  if (enabledPlaneTable) {
    return (
      <Spin spinning={isTableLoading}>
        <Dropdown
          trigger={['contextMenu']}
          overlay={
            <Menu>
              {GENERAL.OPTION_COMMENT.map((el) => (
                <Menu.Item
                  key={el.key}
                  icon={<CommentOutlined />}
                  onClick={() => {
                    setViewComment(true)
                    setHasComment({ type: 'table', data: !hasComment.table })
                  }}
                >
                  {t(el.label)}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <div style={{ width: '100%' }}>
            <PlaneTableUX
              renderers={Object.assign({}, TableRenderers)}
              data={dataSource}
              onChange={(s) => setSettings(s)}
              vals={settings.default_value ? [settings.default_value] : ['amount']}
              cols={fields.columns}
              rows={fields.rows_default}
              aggregatorName={settings.default_format || 'Decimales'}
              hiddenFromDragDrop={fields.values}
              hiddenFromAggregators={fields.rows}
              tableOptions={{
                realMonths: fields?.real_months,
                clickCallback: (info, value, record, pivotData, vals) => {},
              }}
              plotlyConfig={{
                responsive: true,
              }}
              tableFilter={{}}
              {...settings}
            />
          </div>
        </Dropdown>
      </Spin>
    )
  }

  return (
    <Spin spinning={isTableLoading}>
      <Dropdown
        trigger={['contextMenu']}
        overlay={
          <Menu>
            {GENERAL.OPTION_COMMENT.map((el) => (
              <Menu.Item
                key={el.key}
                icon={<CommentOutlined />}
                onClick={() => {
                  setViewComment(true)
                  setHasComment({ type: 'table', data: !hasComment.table })
                }}
              >
                {t(el.label)}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <div style={{ width: '100%' }}>
          <PivotTableUX
            data={dataSource}
            onChange={(s) => setSettings(s)}
            vals={settings.default_value ? [settings.default_value] : ['amount']}
            cols={fields.columns}
            rows={fields.rows_default}
            aggregatorName={settings.default_format || 'Decimales'}
            hiddenFromDragDrop={fields.values}
            hiddenFromAggregators={fields.rows}
            tableOptions={{
              realMonths: fields?.real_months,
              clickCallback: (info, value, record, pivotData, vals) => {},
            }}
            plotlyConfig={{
              responsive: true,
            }}
            tableFilter={{}}
            {...settings}
          />
        </div>
      </Dropdown>
    </Spin>
  )
}

const mapDispatchToProps = {
  fetchOpenRow: control.actions.fetchOpenRow,
}

export default connect(null, mapDispatchToProps)(ProjectionControlTable)
