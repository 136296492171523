import { Button, ButtonActionBar } from 'modules/core/components'

const UserActions = ({ tooltopTitle, onClick, loading, disabled }) => {
  return (
    <ButtonActionBar>
      <Button.Primary
        onClick={onClick}
        loading={loading}
        disabled={disabled}
        title="ACTION_CREATE_NEW"
        tooltipTitle={tooltopTitle}
      />
    </ButtonActionBar>
  )
}

export default UserActions
