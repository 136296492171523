import i18n from 'i18next'
import { ROLES } from 'modules/core/constants'
import { getOperationsByKeys, isUserAllowed } from 'modules/core/utils'
import { RightOutlined } from '@ant-design/icons'

const {
  SETTINGS__BUDGET_TYPE,
  SETTINGS__DIMENSIONS,
  SETTINGS__REPORTS,
  SETTINGS__SECURITY,
  SETTINGS__SECURITY__USERS,
  SETTINGS__SECURITY__ROLES,
  SETTINGS__PROJECTION_ELEMENTS,
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES,
  SETTINGS__PROJECTION_ELEMENTS__CURRENCY,
} = ROLES

const PROJECTION_ELEMENTS_TYPE = {
  rules: {
    role: [SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES],
  },
  currency: {
    role: [SETTINGS__PROJECTION_ELEMENTS__CURRENCY],
  },
}

const INTEGRATION_OPTIONS = [
  {
    id: 'credentials',
    name: 'INTEGRATIONS_CREDENTIALS_TAB_TITLE',
  },
]

export const listDataConfigModules = ({
  dimensionsList,
  budgetTypeList,
  reportList,
  modifiersList,
  history,
}) => [
  {
    title: i18n.t('LABEL_BUDGET_TYPE'),
    url: '/configuracion/tipodepresupuesto/',
    key: 'concepts',
    allowedFor: [SETTINGS__BUDGET_TYPE],
    count: budgetTypeList?.count,
    renderListItem:
      budgetTypeList?.count !== 0 &&
      budgetTypeList?.values.map((type) => <li key={type.id}>{i18n.t(type.name)}</li>),
  },
  {
    title: i18n.t('LABEL_DIMENSIONS'),
    url: '/configuracion/dimensiones/',
    key: 'dimensions',
    allowedFor: [SETTINGS__DIMENSIONS],
    disabled: dimensionsList.count === 0,
    count: dimensionsList.count,
    renderListItem:
      dimensionsList.count !== 0 &&
      dimensionsList.values.map((dimension) => (
        <li
          key={dimension.id}
          onClick={() => history.push(`/configuracion/dimensiones/${dimension.id}`)}
        >
          {dimension.name_destination}
          <RightOutlined className="enter-icon" />
        </li>
      )),
  },
  {
    title: i18n.t('LABEL_TOOLS'),
    url: '/configuracion/herramientas/',
    key: 'projectionElements',
    allowedFor: [SETTINGS__PROJECTION_ELEMENTS],
    count: '',
    disabled: dimensionsList.count === 0,
    renderListItem: modifiersList.values.map(
      (modifier) =>
        isUserAllowed(getOperationsByKeys(PROJECTION_ELEMENTS_TYPE[modifier.key].role)) && (
          <li
            key={modifier.key}
            onClick={() => history.push(`/configuracion/herramientas?tab=${modifier.key}`)}
          >
            {i18n.t(modifier.name)}
            <RightOutlined className="enter-icon" />
          </li>
        ),
    ),
  },
  {
    title: i18n.t('LABEL_REPORTS'),
    url: '/configuracion/reportes/',
    key: 'reports',
    allowedFor: [SETTINGS__REPORTS],
    count: reportList.count,
    disabled: dimensionsList.count === 0,
    renderListItem: reportList.values.map((report) => (
      <li
        key={report.id}
        className={dimensionsList.count === 0 ? 'li-report' : ''}
        onClick={() =>
          dimensionsList.count !== 0 && history.push(`/configuracion/reportes/${report.id}`)
        }
      >
        {i18n.t(report.name)}
        <RightOutlined className="enter-icon" />
      </li>
    )),
  },
  {
    title: i18n.t('LABEL_INTEGRATION'),
    url: '/configuracion/integraciones/',
    key: 'reports',
    allowedFor: [SETTINGS__REPORTS],
    count: '',
    disabled: false,
    renderListItem: INTEGRATION_OPTIONS.map((integation) => (
      <li
        key={integation.id}
        onClick={() => history.push(`/configuracion/integraciones/?tab=${integation.id}`)}
      >
        {i18n.t(integation.name)}
        <RightOutlined className="enter-icon" />
      </li>
    )),
  },
  {
    title: i18n.t('LABEL_SECURITY'),
    url: '/configuracion/seguridad/',
    key: 'security',
    allowedFor: [SETTINGS__SECURITY],
    count: '',
    renderListItem: (
      <>
        {isUserAllowed(getOperationsByKeys([SETTINGS__SECURITY__USERS])) && (
          <li onClick={() => history.push(`/configuracion/seguridad/?tab=users`)}>
            {i18n.t('LABEL_USERS')}
            <RightOutlined className="enter-icon" />
          </li>
        )}
        {isUserAllowed(getOperationsByKeys([SETTINGS__SECURITY__ROLES])) && (
          <li onClick={() => history.push(`/configuracion/seguridad/?tab=roles`)}>
            {i18n.t('LABEL_ROLES')}
            <RightOutlined className="enter-icon" />
          </li>
        )}
      </>
    ),
  },
]
