import { Input, Popover, Space } from 'antd'
import { StarOutlined, StarFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Typography from '../../Typography/Typography'
import ButtonActionBar from '../../ButtonActionBar/ButtonActionBar'
import Button from '../../Button/Button'

const FavouriteIcon = ({
  visible,
  favouriteName,
  setFavouriteName,
  onClickCancelSaveFav,
  onClickSave,
  loading,
  alreadyExist,
  onClickSaveFav,
}) => {
  const { t } = useTranslation()
  return (
    <Popover
      trigger="click"
      visible={visible}
      content={
        <Space direction="vertical">
          <Typography.Body level={3}>{t('TYPOGRAPHY_ENTER_VIEW_NAME')}</Typography.Body>
          <Input
            placeholder={t('ENTER_NAME_PLACEHOLDER')}
            value={favouriteName}
            onChange={(val) => setFavouriteName(val.target.value)}
          />
          <ButtonActionBar>
            <Button.Default title="ACTION_CANCEL" onClick={onClickCancelSaveFav} />
            <Button.Primary title="ACTION_SAVE" onClick={onClickSave} loading={loading} />
          </ButtonActionBar>
        </Space>
      }
    >
      <Button.Icon
        title={alreadyExist ? t('FIELD_FAVORITE') : t('ACTION_ADD_FAVORITE')}
        icon={<Typography.Icon icon={alreadyExist ? StarFilled : StarOutlined} />}
        onClick={!alreadyExist && onClickSaveFav}
        style={alreadyExist ? { cursor: 'default' } : {}}
      />
    </Popover>
  )
}

export default FavouriteIcon
