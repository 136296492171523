import React, { useState } from 'react'
import { Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { disabledDate, forecastCreate } from './utils'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { generateSelectOptions } from 'modules/core/utils'
import { PROJECTION } from 'modules/core/constants'
import moment from 'moment'
import forecast from 'modules/forecast'
import _ from 'lodash'

const CreateForecastModal = ({
  visible,
  onCancel,
  actualPeriod,
  onRefetch,
  createForecast,
  cutDates,
  disabledMonths,
  optionsSelect,
}) => {
  const [date, setDate] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  let { periodId } = useParams()
  const { t } = useTranslation()

  const onClose = () => {
    form.resetFields()
    setIsLoading(false)
    setDate('')
    onCancel()
  }

  const handleCreate = (values) => {
    const { overall, name, cutoff_date } = values
    const id = parseInt(overall.split('_')[0])
    const type = overall.split('_')[1]
    const findOption = optionsSelect.find((el) => el.id === id && el.type === type)
    const data = {
      name,
      cutoff_date: moment(cutoff_date).format('YYYY-MM'),
      period_id: periodId,
      ...(findOption && findOption.type === 'budget'
        ? {
            overall_budget: id,
          }
        : {
            forecast_overall_budget: id,
          }),
    }
    return createForecast(data)
  }

  return (
    <Modal
      title={t('FORECAST_CREATE_ACTION')}
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      centered
      forceRender
      visible={visible}
      onCancel={onClose}
      width={568}
      okButtonProps={{ loading: isLoading, disabled: !date }}
      onOk={() =>
        forecastCreate({
          form,
          setIsLoading,
          createForecast: (values) => handleCreate(values),
          onRefetch,
          onClose,
        })
      }
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Typography.Body level={2}>
              {t('FORECAST_CUT_DATE_INFORMATIVE_TEXT_MODAL')}
            </Typography.Body>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('FIELD_NAME')}
              name="name"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="cutoff_date"
              label={t('FORECAST_CUTTING_MONTH_FORM_CREATE_MODAL_LABEL')}
              rules={[
                {
                  required: true,
                  message: t('REQUIRED_FIELD'),
                },
              ]}
            >
              <DatePicker
                defaultPickerValue={
                  !_.isEmpty(actualPeriod) ? moment(actualPeriod?.start_date).utc() : null
                }
                placeholder={t('FORECAST_CUT_DATE_PLACEHOLDER_DATEPICKER_MODAL')}
                format="MMMM YYYY"
                picker="month"
                style={{ width: 250 }}
                disabledDate={(date) => disabledDate(date, cutDates, disabledMonths)}
                onChange={setDate}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="overall"
              label={t('FORECAST_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL')}
              rules={[
                {
                  required: true,
                  message: t('REQUIRED_FIELD'),
                },
              ]}
            >
              <Select
                placeholder={t('ACTION_SELECT')}
                options={generateSelectOptions({
                  options: optionsSelect.map((el) => {
                    return {
                      id: `${el.id}_${el.type}`,
                      name: `${el.name} (${t(el.type)})-(${t(
                        PROJECTION.STATUS_COLORS[el.status].name,
                      )})`,
                    }
                  }),
                })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createForecast: forecast.actions.createForecast,
}

export default connect(null, mapDispatchToProps)(CreateForecastModal)
