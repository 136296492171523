import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  CreateGlobalProjectionModal,
  EmptyScreen,
  ProjectionCardList,
  UserActionsGlobal,
} from 'modules/core/components'
import { Spin, Row, Col } from 'antd'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { handleDragEnd, handleDragStart, isApproved } from 'modules/core/utils'
import { PROJECTION, ROLES } from 'modules/core/constants'
import {
  DndContext,
  useSensor,
  useSensors,
  closestCenter,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core'
import planning from 'modules/planning'
import configuration from 'modules/configuration'

const { PLANNING__GLOBAL_BUDGET__VIEW, PLANNING__GLOBAL_BUDGET__ABM } = ROLES

const PLANNING_CONCEPT = {
  [PROJECTION.GLOBAL_BUDGET]: {
    VIEW: PLANNING__GLOBAL_BUDGET__VIEW,
    ABM: PLANNING__GLOBAL_BUDGET__ABM,
  },
}

const GlobalBudgetList = ({
  globalBudgetList,
  fetchGlobalBudgetList,
  deleteGlobalBudget,
  editGlobalBudgetName,
  setDefaultBudget,
  createGlobalBudget,
  fetchBudgetInstanceList,
  budgetInstanceList,
  orderGlobalBudgetCard,
  setGlobalBudgetOrder,
  changeCardColor,
  duplicateGlobalBudget,
  fetchCommentsList,
  commentsList,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [dataComments, setDataComments] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  let { periodId } = useParams()
  const { t } = useTranslation()
  let history = useHistory()
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `${auxKey}?tab=globalBudget` : auxKey

  useEffect(() => {
    setIsLoadingComments(true)
    fetchCommentsList({ module: key }).then(() => setIsLoadingComments(false))
  }, [fetchCommentsList, key])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    setIsLoading(true)
    fetchGlobalBudgetList({ period_id: periodId }).then(() => {
      setIsScreenLoading(false)
      setIsLoading(false)
    })
    return () => {
      setIsScreenLoading(false)
      setIsLoading(false)
    }
  }, [fetchGlobalBudgetList, periodId])

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PLANNING_GLOBAL_BUDGET_EMPTY_SCREEN_TITLE')}
      footer={
        <CreateGlobalProjectionModal
          periodId={periodId}
          title={t('PLANNING_GLOBAL_BUDGET_CREATE_FORM_TITLE')}
          globalText="LABEL_GLOBAL_BUDGET"
          role={PLANNING__GLOBAL_BUDGET__ABM}
          onFetchGlobalBudgetList={() => fetchGlobalBudgetList({ period_id: periodId })}
          text={t('LABEL_GLOBAL_BUDGET').toLowerCase()}
          createGlobalProjection={createGlobalBudget}
          type="planning"
          fetchProjectionInstanceList={(conceptId) => fetchBudgetInstanceList(conceptId, periodId)}
          projectionInstanceList={budgetInstanceList}
        />
      }
    />
  )

  const renderTopActions = () => (
    <UserActionsGlobal
      globalBudgetApproved={globalBudgetList?.values.filter((globalBudget) =>
        isApproved(globalBudget.status),
      )}
      periodId={periodId}
      title={t('PLANNING_GLOBAL_BUDGET_CREATE_FORM_TITLE')}
      globalText="LABEL_GLOBAL_BUDGET"
      role={PLANNING__GLOBAL_BUDGET__ABM}
      onFetchGlobalBudgetList={() => fetchGlobalBudgetList({ period_id: periodId })}
      createGlobalProjection={createGlobalBudget}
      type="planning"
      fetchProjectionInstanceList={(conceptId) => fetchBudgetInstanceList(conceptId, periodId)}
      projectionInstanceList={budgetInstanceList}
      setSearchValue={setSearchValue}
      module_name="planning_global"
    />
  )

  const renderDataSource = () => {
    if (searchValue) {
      return globalBudgetList?.values
        .asMutable()
        .filter((el) => el.name.toLowerCase().includes(searchValue.toLowerCase()))
    } else {
      return globalBudgetList?.values.asMutable()
    }
  }

  const renderGlobalBudgetList = () => (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={(event) => handleDragStart(event, setActiveId)}
      onDragEnd={(event) =>
        handleDragEnd({
          event,
          values: globalBudgetList?.values,
          order: orderGlobalBudgetCard,
          setOrder: setGlobalBudgetOrder,
          type: 'overall_budget_ids',
        })
      }
    >
      <ProjectionCardList
        dataSource={renderDataSource()}
        type={PROJECTION.GLOBAL_BUDGET}
        role={PLANNING_CONCEPT}
        onOpen={(id) => history.push(`/planificacion/${periodId}/presupuestoglobal/${id}`)}
        reloadList={() => fetchGlobalBudgetList({ period_id: periodId })}
        deleteProjection={deleteGlobalBudget}
        editProjectionName={editGlobalBudgetName}
        setDefaultProjection={setDefaultBudget}
        activeId={activeId}
        loading={isLoading}
        changeCardColor={changeCardColor}
        module="globalBudget"
        duplicateCard={duplicateGlobalBudget}
        setDataComments={setDataComments}
        dataComments={dataComments}
        onRefetchComments={() => fetchCommentsList({ module: key })}
        loadingComments={isLoadingComments}
      />
    </DndContext>
  )

  return (
    <Spin spinning={isScreenLoading} size="large">
      {!isScreenLoading && (
        <Row gutter={[24, 24]}>
          {globalBudgetList?.count <= 0 && <Col span={24}>{renderNoDataScreen()}</Col>}
          {globalBudgetList?.count > 0 && (
            <>
              <Col span={24}>{renderTopActions()}</Col>
              <Col span={24}>{renderGlobalBudgetList()}</Col>
            </>
          )}
        </Row>
      )}
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  globalBudgetList: planning.selectors.getGlobalBudgetList(state),
  budgetInstanceList: planning.selectors.getBudgetInstanceList(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  fetchGlobalBudgetList: planning.actions.fetchGlobalBudgetList,
  deleteGlobalBudget: planning.actions.deleteGlobalBudget,
  editGlobalBudgetName: planning.actions.editGlobalBudgetName,
  setDefaultBudget: planning.actions.setDefaultBudget,
  createGlobalBudget: planning.actions.createGlobalBudget,
  fetchBudgetInstanceList: planning.actions.fetchBudgetInstanceList,
  orderGlobalBudgetCard: planning.actions.orderGlobalBudgetCard,
  setGlobalBudgetOrder: planning.actions.setGlobalBudgetOrder,
  changeCardColor: planning.actions.changeCardColor,
  duplicateGlobalBudget: planning.actions.duplicateGlobalBudget,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalBudgetList)
