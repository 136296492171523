import React, { useRef, useState } from 'react'
import numeral from 'numeral'
import { Card, Dropdown, Menu, message } from 'antd'
import { CHARTS, GENERAL } from 'modules/core/constants'
import { LineChart } from 'modules/core/components'
import { ExtraActionsChart } from './components'
import { CommentOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { generateMessageError } from 'modules/core/utils'
import login from 'modules/login'
import configuration from 'modules/configuration'
import _ from 'lodash'

const GlobalProjectionLineChart = ({
  title,
  exportTitle = '',
  data,
  prefix = '',
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  loggedUser,
  hasComment,
  setHasComment,
  createComment,
  removeNewComment,
}) => {
  const [viewComment, setViewComment] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const chartRef = useRef(null)
  const { t } = useTranslation()

  const key = window.location.pathname.replaceAll('/', '-')

  const renderExtraActions = () => (
    <ExtraActionsChart
      chartRef={chartRef}
      exportTitle={exportTitle}
      showComment={
        viewComment ||
        dataComments.filter((el) => el.key === `${key}-chart-0` && !el.resolved).length > 0
      }
      setHasComment={setHasComment}
      hasComment={hasComment}
      setViewComment={setViewComment}
      setDataComments={setDataComments}
      dataComments={dataComments.filter(
        (el) => (el.key === `${key}-chart-0` && !el.resolved) || !el.text,
      )}
      onRefetchComments={onRefetchComments}
      loadingComments={loadingComments || isSaving}
      onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
        handleCreate({ text, usersIds, idComment, setIsNewThread })
      }
      userDetail={loggedUser}
      onCleanHasComment={() => {
        setHasComment({ type: 'chart', data: false, key, id: 0 })
        setViewComment(false)
      }}
      removeNewComment={removeNewComment}
    />
  )

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-chart-0`,
      module: key,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  return (
    <Dropdown
      trigger={['contextMenu']}
      overlay={
        <Menu>
          {GENERAL.OPTION_COMMENT.map((el) => (
            <Menu.Item
              key={el.key}
              icon={<CommentOutlined />}
              onClick={() => {
                setViewComment(true)
                setHasComment({
                  type: 'chart',
                  data: !_.isEmpty(hasComment?.chart)
                    ? !_.isEmpty(hasComment?.chart[key])
                      ? !hasComment?.chart[key]['0']
                      : true
                    : true,
                  key: key,
                  id: 0,
                })
              }}
            >
              {t(el.label)}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <div ref={chartRef}>
        <Card title={title} headStyle={{ height: 56 }} extra={renderExtraActions()}>
          <LineChart
            containerHeight={300}
            data={data}
            colors={CHARTS.COLORS}
            axisLeft={{
              format: (value) => `${prefix} ${numeral(value).format('0,0[.]0 a')}`,
            }}
            value={data.map((val) => val)}
          />
        </Card>
      </div>
    </Dropdown>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalProjectionLineChart)
